import {
  Calculation,
  CalculationActions,
} from '../generated/model/calculation';
import {
  testCalculationGroup,
  testCalculationGroupItem,
  testCalculationItem,
} from './calculationItem';
import { testCalculationPerspective } from './calculationPerspective';

export const testCalculation: Calculation = {
  actions: [CalculationActions.CreateOffer, CalculationActions.ViewOffer],
  id: 1,
  offer_id: 2,
  items: [testCalculationItem, testCalculationGroup, testCalculationGroupItem],
  is_read_only: false,
  general_agent: testCalculationPerspective,
  partner: testCalculationPerspective,
  customer: testCalculationPerspective,
};
