import { Action, createReducer, on } from '@ngrx/store';
import { CurrencyActions } from './currency.actions';

export enum CurrencyScope {
  General = 'General',
  ContextSpecific = 'ContextSpecific',
}
export interface CurrencyState {
  /**
   * Currency settings from user settings
   */
  [CurrencyScope.General]: CurrencySettingsState | null;
  /**
   * Currency settings in current project
   */
  [CurrencyScope.ContextSpecific]: CurrencySettingsState | null;
  /*
   * Scope of currency settings
   */
  scope: CurrencyScope;
}
export interface CurrencySettingsState {
  ratio: number;
  locale?: string | null;
  code?: string | null;
  symbol?: string | null;
  rounding_digits: number;
  valid_from: Date;
}

const initialState: CurrencyState = {
  [CurrencyScope.General]: null,
  [CurrencyScope.ContextSpecific]: null,
  scope: CurrencyScope.General,
};

const reducer = createReducer(
  initialState,
  on(CurrencyActions.SetGeneralCurrency, (state, { payload }) => ({
    ...state,
    [CurrencyScope.General]: payload,
  })),
  on(CurrencyActions.SetContextSpecificCurrency, (state, { payload }) => ({
    ...state,
    [CurrencyScope.ContextSpecific]: payload ?? null,
    scope: CurrencyScope.ContextSpecific,
  })),
  on(CurrencyActions.SetScope, (state, { payload }) => ({
    ...state,
    scope: payload,
  })),
);

export function currencyReducer(
  state: CurrencyState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
