import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SpotlightState } from './shared/spotlight.reducer';
import { SpotlightSelectors } from './shared/spotlight.selectors';
import { DsSpotlightComponent } from './spotlight.component';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[spotlight]',
})
export class DsSpotlightDirective implements AfterViewInit, OnDestroy {
  @Input() spotlight: DsSpotlightComponent;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private _store: Store<SpotlightState>,
    private _elementRef: ElementRef<HTMLElement>,
    private _renderer: Renderer2,
  ) {}

  ngAfterViewInit() {
    this._store
      .select(SpotlightSelectors.isSpotLightOpen(this.spotlight.id))
      .pipe(takeUntil(this.destroy$))
      .subscribe((isSpotLightOpen) => {
        if (isSpotLightOpen) {
          this._renderer.addClass(this._elementRef.nativeElement, 'spotlight');
          // we use any here to check if there is scrollIntoViewIfNeeded function which only certain browsers support
          const nativeElement = this._elementRef.nativeElement as any;
          if (nativeElement.scrollIntoViewIfNeeded) {
            nativeElement.scrollIntoViewIfNeeded(true);
          } else {
            nativeElement.scrollIntoView(false);
          }
        } else {
          this._renderer.removeClass(
            this._elementRef.nativeElement,
            'spotlight',
          );
        }
      });

    this.spotlight.element = this._elementRef;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
