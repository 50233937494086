/**
 * SalesSolutions Backend
 * Collection of APIs for SalesSolutions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Offer Image Dto
 */
export interface OfferImage { 
    /**
     * Id
     */
    
    id: number;
    /**
     * Cart Id
     */
    
    cart_id: number;
    /**
     * File Url
     */
    
    file_url?: string | null;
    /**
     * Offer Image Type
     */
    
    type: OfferImageType;
}
export enum OfferImageType {
    FrontPage = 'FrontPage'
};



