import { SalesPriceListOptionMappingValue } from '../generated/model/salesPriceListOptionMappingValue';

export const testSalesPriceListOptionMappingValue: SalesPriceListOptionMappingValue =
  {
    id: 'Test SalesPriceListOptionMappingValue Id',
    description: 'Test SalesPriceListOptionMappingValue Description',
    state: 'Test SalesPriceListOptionMappingValue Steate',
    precondition: ['Test SalesPriceListOptionMappingValue Precondition'],
    disabled_by: ['Test SalesPriceListOptionMappingValue Disabled By'],
  };
