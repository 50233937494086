<h1 mat-dialog-title>
  {{ 'sales_shared.debug_view.questionnaire_dialog.title' | translate }}
</h1>
<div
  mat-dialog-content
  *ngIf="answer.disabled_by?.length"
  data-cy="answer-disabled-by"
>
  <ng-container>
    <p>
      {{
        'sales_shared.debug_view.questionnaire_dialog.disabled_by_answers_combination'
          | translate
      }}:
    </p>
    <ul *ngFor="let item of answer.disabled_by">
      <li *ngIf="item.type === 'Answer'">
        <strong
          >{{ 'sales_shared.debug_view.question_label' | translate }}:
        </strong>
        {{ item.answer?.question_heading }}
        <br />
        <strong
          >{{ 'sales_shared.debug_view.answer_label' | translate }}: </strong
        >{{ item.answer?.heading }}
      </li>
    </ul>
  </ng-container>
</div>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>
    {{ 'general.close' | translate }}
  </button>
</mat-dialog-actions>
