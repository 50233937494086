<footer class="main-footer" fxLayoutAlign="center">
  <div
    class="inner-wrap"
    fxLayout="row wrap"
    fxLayoutAlign="space-between center"
    fxLayoutAlign.lt-md="center center"
  >
    <p>© {{ copyrightYear }} PALFINGER AG</p>
    <nav fxLayout="row wrap">
      <a
        [href]="
          palfingerAppBasePath +
          (language | slScMapPalfingerLang) +
          privacy_policy
        "
        target="_blank"
        rel="noopener noreferrer"
        data-cy="palfinger-link"
        >{{ 'turnkey_solution.footer.privacy_policy' | translate }}</a
      >
      <a
        [href]="
          palfingerAppBasePath +
          (language | slScMapPalfingerLang) +
          code_of_conduct
        "
        target="_blank"
        rel="noopener noreferrer"
        data-cy="palfinger-link"
        >{{ 'turnkey_solution.footer.code_of_conduct' | translate }}</a
      >
      <a
        [href]="
          palfingerAppBasePath +
          (language | slScMapPalfingerLang) +
          integrity_line
        "
        target="_blank"
        rel="noopener noreferrer"
        data-cy="palfinger-link"
        >{{ 'turnkey_solution.footer.integrity_line' | translate }}</a
      >
      <a
        [href]="
          palfingerAppBasePath +
          (language | slScMapPalfingerLang) +
          terms_and_conditions
        "
        target="_blank"
        rel="noopener noreferrer"
        data-cy="palfinger-link"
        >{{ 'turnkey_solution.footer.terms_and_conditions' | translate }}</a
      >
      <a
        href="https://vdp.palfinger.com/p/Vulnerability-Disclosure-Policy"
        target="_blank"
        rel="noopener noreferrer"
        data-cy="palfinger-link"
        >{{
          'turnkey_solution.footer.vulnerability_disclosure_policy' | translate
        }}</a
      >
    </nav>
  </div>
</footer>
