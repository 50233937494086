<h3 mat-dialog-title>
  {{ 'customer.confirm_order.headline' | translate
  }}<span *ngIf="data.street">: {{ data.street || data.street_name }}</span>
</h3>
<mat-dialog-content>
  <span>
    {{ 'customer.confirm_order.message' | translate }}
  </span>
  <br /><br />
  <form [formGroup]="form">
    <div
      fxLayout="row"
      [fxLayoutGap]="1 | dsSpacing"
      fxLayout.xs="column"
      fxLayoutGap.xs="0"
    >
      <div fxFlex>
        <mat-form-field class="full-width">
          <mat-label>{{ 'customer.street' | translate }}</mat-label>
          <input
            matInput
            maxlength="35"
            [formControlName]="nameof('street_name')"
            required
          />
          <mat-error>
            {{ 'customer.not_empty_hint' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex>
        <mat-form-field class="full-width">
          <mat-label>{{ 'customer.house_number' | translate }}</mat-label>
          <input
            matInput
            maxlength="10"
            [formControlName]="nameof('house_number')"
            required
          />
          <mat-error>
            {{ 'customer.not_empty_hint' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions *pdLet="isLoading$ | async as isLoading">
  <button mat-button [mat-dialog-close]="false">
    {{ 'general.cancel' | translate }}
  </button>
  <button
    mat-raised-button
    color="accent"
    [disabled]="form.invalid || isLoading"
    (click)="updateCustomer()"
    [dsButtonPending]="isLoading"
  >
    {{ 'general.confirm' | translate }}
  </button>
</mat-dialog-actions>
