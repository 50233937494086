/**
 * SalesSolutions Backend
 * Collection of APIs for SalesSolutions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Shared Configuration . Based on this payload shared configuration link can be provided
 */
export interface SharedConfiguration { 
    /**
     * e.g. \"PC 2700\"
     */
    
    model_id?: string | null;
    /**
     * <example>001</example>  <example>010</example>  <example>011</example>
     */
    
    object_type: string;
    /**
     * Price list date
     */
    
    pricelist_date?: string | null;
    /**
     * Product type
     */
    
    product_type: SharedConfigurationProductType;
    /**
     * ProductId
     */
    
    product_id: number;
    /**
     * Product line
     */
    
    product_line?: string | null;
    /**
     * Configuration option names and values
     */
    
    options: { [key: string]: string | undefined; };
}
export enum SharedConfigurationProductType {
    Product = 'Product',
    Accessory = 'Accessory'
};



