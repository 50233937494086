<section class="container sl-sc-stepper">
  <header>
    <div
      class="step-navigation-bar"
      [fxLayoutAlign.lt-sm]="
        selectedIndex !== steps.length - 1 ? 'center space-between' : ''
      "
      fxLayout.lt-sm="row wrap"
    >
      <button
        fxFlex.lt-sm="45"
        fxLayoutAlign="flex-start flex-end"
        class="nav-button previous"
        (click)="prevStep()"
        *ngIf="selectedIndex !== 0"
      >
        <mat-icon>chevron_left</mat-icon>
        <ng-container [ngTemplateOutlet]="prevLabelTemp"></ng-container>
      </button>

      <button
        mat-button
        [fxFlex.lt-sm]="selectedIndex !== 0 ? 100 : 50"
        [fxFlexOrder.lt-sm]="selectedIndex !== 0 ? 2 : 1"
        [matMenuTriggerFor]="menu"
        class="sl-sc-steps-menu-button"
        disableRipple="true"
      >
        <h4 class="step-label" fxLayoutAlign="center flex-end">
          <ng-container
            *ngIf="selected?.stepLabel; else showLabelText"
            [ngTemplateOutlet]="selected?.stepLabel?.template"
          >
          </ng-container>
          <ng-template #showLabelText>
            {{ selected?.label }}
          </ng-template>

          <span class="step-number">
            ({{ selectedIndex + 1 }}/{{ steps.length }})
          </span>
          <mat-icon class="steps-arrow">expand_more</mat-icon>
        </h4>
      </button>

      <mat-menu #menu="matMenu" data-test="step-menu">
        <button
          mat-menu-item
          *ngFor="let step of scSteps"
          (click)="stepChanged(step.label)"
          [disabled]="!step.isCompleted"
        >
          {{ step.label }}
        </button>
      </mat-menu>

      <button
        [fxFlex.lt-sm]="selectedIndex === 0 ? 100 : 45"
        fxLayoutAlign="flex-end flex-end"
        class="nav-button next"
        (click)="nextStep()"
        *ngIf="selectedIndex !== steps.length - 1"
        [disabled]="!selected?.completed && isNextButtonDisabled"
      >
        <ng-container [ngTemplateOutlet]="nextLabelTemp"></ng-container>
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
    <mat-progress-bar
      [value]="((selectedIndex + 1) / steps.length) * 100"
    ></mat-progress-bar>
  </header>

  <div class="step-content" [style.display]="selected ? 'block' : 'none'">
    <!-- Content from the CdkStep is projected here -->
    <ng-container [ngTemplateOutlet]="selected?.content"></ng-container>
  </div>
</section>
