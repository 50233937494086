import { ServiceContract } from '../generated/model/serviceContract';
import { testCustomer } from './customer';

export const testServiceContract: ServiceContract = {
  contract_id: 1,
  cart_item_id: 'Test Cart Item Id',
  cart_id: 'Test Cart Id',
  general_agent_service_contract_price_per_year: 2,
  legal_inspection_per_year: 3,
  general_agent_discount_per_year: 4,
  end_customer_recommended_price_per_year: 5,
  end_customer_payment: 6,
  connected_price: 7,
  valid_until: new Date(),
  customer: testCustomer,
  contact_name: 'Test Contact Name',
  contact_email: 'Test Contact Email',
  quotation_id: 'Test Quotation Id',
  product_name: 'Test Product Name',
  contract_period: 8,
  tank_size: 9,
  operation_time: 10,
  end_customer_recommended_price: 11,
  dealer: testCustomer,
  currency: 'Test Currency',
  equipment_number: 'Test Equipment Number',
};
