/**
 * SalesSolutions Backend
 * Collection of APIs for SalesSolutions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SalesPriceListOptionErrorAttribute } from './salesPriceListOptionErrorAttribute';
import { SalesPriceListOptionCategory } from './salesPriceListOptionCategory';
import { SalesPriceListPackage } from './salesPriceListPackage';


/**
 * Holds the sales price list data
 */
export interface SalesPriceList { 
    /**
     * Gets or sets the model identifier of the sales price list.
     */
    
    id: string;
    /**
     * Gets or sets the object type of the sales price list.
     */
    
    object_type: string;
    /**
     * Gets or set the configurability of the sales price list
     */
    
    configurable: boolean;
    /**
     * Gets or sets the categories of the sales price list options.
     */
    
    categories?: Array<SalesPriceListOptionCategory> | null;
    /**
     * Gets or sets the packages of the sales price list.
     */
    
    packages?: Array<SalesPriceListPackage> | null;
    /**
     * SAP Model id
     */
    
    state: SalesPriceListState;
    /**
     * Gets or sets a collection of error attributes associated with sales price list options.
     */
    
    error_attributes?: Array<SalesPriceListOptionErrorAttribute> | null;
}
export enum SalesPriceListState {
    VALID = 'VALID',
    INVALID = 'INVALID'
};



