import { Highlight } from '../generated/model/highlight';
import { testMedia } from './media';

export const testHighlight: Highlight = {
  id: 1,
  title: 'Test Title',
  teaser_text: 'Test Teaser Text',
  subtitle: 'Test Subtitle',
  headline: 'Test Healine',
  image: testMedia,
  teaser_image: testMedia,
  affiliatelinks: 'Test Affiliate Links',
};
