/**
 * SalesSolutions Backend
 * Collection of APIs for SalesSolutions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PriceListOptionChange { 
    /**
     * Option name
     */
    
    name: string;
    /**
     * New option state
     */
    
    state: PriceListOptionChangeState;
    /**
     * Optional value for wildcard options
     */
    
    value?: string | null;
}
export enum PriceListOptionChangeState {
    SELECTED = 'SELECTED',
    DESELECTED = 'DESELECTED',
    SELECTABLE = 'SELECTABLE',
    INVALID = 'INVALID',
    REQUIRED = 'REQUIRED'
};



