import { Currency } from '../generated/model/currency';

export const testCurrency: Currency = {
  ratio: 1.34,
  rounding_digits: 1,
  code: 'USD',
  locale: 'en',
  symbol: '$',
  valid_from: new Date('2021-01-01'),
};
