import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';

export interface SlSharedScNavigationStepperState {
  isLinear: boolean;
  isEditable: boolean;
  currentStep: number;
}

const initialState: SlSharedScNavigationStepperState = {
  isLinear: false,
  isEditable: true,
  currentStep: 0,
};

@Injectable()
export class SlSharedScNavigationStepperStore extends ComponentStore<SlSharedScNavigationStepperState> {
  readonly isLinear = this.select((state) => state.isLinear);
  readonly isEditable = this.select((state) => state.isEditable);
  currentStep = this.select((state) => state.currentStep);

  constructor() {
    super(initialState);
  }

  readonly updateCurrentStep = this.updater((state, stepNumber: number) => ({
    ...state,
    currentStep: stepNumber,
  }));
}
