/**
 * SalesSolutions Backend
 * Collection of APIs for SalesSolutions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PackageOptionCompare } from './packageOptionCompare';
import { CompareDifference } from './compareDifference';


/**
 * Represents a Data Transfer Object (DTO) for comparing packages.
 */
export interface PackageCompare { 
    /**
     * The name of the package.
     */
    
    name?: string | null;
    /**
     * The type of object represented by the package.
     */
    
    object_type?: string | null;
    /**
     * The unique identifier of the model associated with the package.
     */
    
    model_id?: string | null;
    /**
     * A description of the package.
     */
    
    short_description?: string | null;
    /**
     * The sales price of the package.
     */
    
    sales_price: number;
    /**
     * The purchase price of the package.
     */
    
    purchase_price: number;
    /**
     * Indicates whether the package is fulfilled.
     */
    
    fulfilled: boolean;
    /**
     * The type of differences found during the comparison of packages.
     */
    
    compare_difference_type: PackageCompareCompareDifferenceType;
    /**
     * A list of package options for comparison, if available.
     */
    
    package_options?: Array<PackageOptionCompare> | null;
    
    changes?: CompareDifference;
    /**
     * The item type.
     */
    
    type: PackageCompareType;
}
export enum PackageCompareCompareDifferenceType {
    Added = 'Added',
    Changed = 'Changed',
    Removed = 'Removed',
    Unchanged = 'Unchanged'
};
export enum PackageCompareType {
    Crane = 'Crane',
    Accessory = 'Accessory',
    Custom = 'Custom',
    TuningCenter = 'TuningCenter',
    Option = 'Option',
    WarrantyExtension = 'WarrantyExtension',
    Contract = 'Contract',
    MCC = 'MCC',
    Package = 'Package',
    ConfigurationOption = 'ConfigurationOption'
};



