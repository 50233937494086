import { Variant } from '../generated/model/variant';
import { testAttribute } from './attribute';
import { testMedia } from './media';

export const testVariant: Variant = {
  id: 1,
  code: 'Test',
  name: 'Test Name',
  fly_jib: false,
  attributes: {
    Attribute1: testAttribute,
    Attribute2: {
      ...testAttribute,
      name: 'Test Attribute 2',
      data: { data: 2 },
    },
  },
  metric_image: testMedia,
  imperial_image: testMedia,
};
