import {
  CustomArticlePriceList,
  CustomArticlePriceListAccessLevel,
} from '../generated/model/customArticlePriceList';

export const testCustomArticlePriceList: CustomArticlePriceList = {
  id: 1,
  category_id: 2,
  category_name: 'Test Category Name',
  name: 'Test Name',
  access_level: CustomArticlePriceListAccessLevel.Public,
};
