import { createAction, props } from '@ngrx/store';
import { Currency } from '@sales-libs/settings/data-access';
import { CurrencyScope, CurrencySettingsState } from './currency.reducer';

export namespace CurrencyActions {
  export const SetGeneralCurrency = createAction(
    '[SalestoolShared] SET_GENERAL_CURRENCY',
    props<{ payload: Currency }>(),
  );
  export const SetContextSpecificCurrency = createAction(
    '[SalestoolShared] SET_CONTEXT_SPECIFIC_CURRENCY',
    props<{ payload?: Currency | CurrencySettingsState }>(),
  );

  export const SetScope = createAction(
    '[SalestoolShared] SET_CURRENCY_SETTINGS_SCOPE',
    props<{ payload: CurrencyScope }>(),
  );
}
