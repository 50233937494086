<div #view class="carousel" [style.height]="height">
  <ng-container *ngFor="let slide of slides; let i = index">
    <img
      [alt]="slide.headline"
      *ngIf="i === currentSlide"
      [src]="slide.src"
      class="slide"
      [@slideAnimation]="animationType"
    />
    <div *ngIf="i === currentSlide" class="mask"></div>
    <div
      class="headline"
      *ngIf="i === currentSlide"
      (swipe)="slideCarousel($event)"
    >
      <div class="padding-bottom-50">
        <h4 fxLayout="row" fxLayoutAlign="center center">
          {{ slide.headline }}
        </h4>
        <p fxLayout="row" fxLayoutAlign="center center">
          {{ slide.description }}
        </p>
        <ng-content></ng-content>
      </div>
    </div>
  </ng-container>
  <ol class="carousel-indicators">
    <li
      *ngFor="let ind of slides; let inc = index"
      [ngClass]="{ 'active-slide': inc === currentSlide }"
      (click)="slideToImage(inc)"
    ></li>
  </ol>
  <button mat-icon-button class="control prev" (click)="previousSlide()">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <button mat-icon-button class="control next" (click)="nextSlide()">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
</div>
