import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { Answer } from '@sales-libs/sc-questionnaire/data-access';

@Component({
  selector: 'sl-sc-answer-dialog',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatDialogModule, MatButtonModule],
  templateUrl: './answer-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlScAnswerDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public answer: Answer) {}
}
