import { TuningCenterItem } from '../generated';

export const testTuningCenterItem: TuningCenterItem = {
  original_sales_price: 59.99,
  quantity: 1,
  is_edited: false,
  company_bpm_id: 12345,
  cart_item_price_comparer_id: '2',
  sort_key: 1,
  id: 'testTuningCenterItemId',
  cart_id: 67890,
  name: 'testTuningCenterItemName',
  sales_price: 49.99,
  purchase_price: 39.99,
  selected_options: [1, 2, 3],
  is_discountable: true,
  is_optional: false,
  cart_item_group_id: 1,
  sort_key_optional: 0,
  short_description: 'testTuningCenterItemShortDescription',
  long_description: 'testTuningCenterItemLongDescription',
};
