import { ProductItemInput, ProductItemInputProductType } from '../generated';
import { testConfigurationAttribute } from './configurationAttribute';
import { testConfigurationOption } from './configurationOption';
import { testConfigurationPackage } from './configurationPackage';

export const testProductItemInput: ProductItemInput = {
  id: 'testProductId',
  cart_id: 12345,
  short_description: 'testShortDescription',
  long_description: 'testLongDescription',
  model_id: 'testModelId',
  name: 'testProductName',
  material_id: 'testMaterialId',
  object_type: 'testObjectType',
  picture_name: 'testPictureName',
  pim_core_id: 'testPimCoreId',
  packages: [testConfigurationPackage],
  options: [testConfigurationOption],
  attributes: [testConfigurationAttribute],
  sales_price: 99.99,
  purchase_price: 79.99,
  is_valid: true,
  cart_item_group_id: 1,
  quantity: 3,
  is_optional: false,
  product_type: ProductItemInputProductType.Product,
  is_configurable: true,
  sort_key: 0,
  sort_key_optional: 0,
  is_edited: false,
  is_discountable: true,
  is_ppi_relevant: true,
};
