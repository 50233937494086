<ng-template>
  <mat-card
    class="mat-elevation-z10"
    [class.show-overflow]="animation !== 'none'"
  >
    <mat-card-content>
      <ng-content></ng-content>
      <br /><br />
      <ng-container *ngIf="spotlightsTotal$ | async as spotlightsTotal">
        <div
          fxLayout
          fxLayoutAlign="space-between center"
          class="actions"
          *ngIf="index$ | async as index"
        >
          <div>
            <div *ngIf="spotlightsTotal > 1" class="nowrap-text">
              {{ index }} /
              {{ spotlightsTotal }}
            </div>
          </div>
          <div fxLayout>
            <button mat-button (click)="onPreviousClick()" *ngIf="index !== 1">
              {{ 'general.return' | translate }}
            </button>
            <button
              mat-button
              (click)="onNextClick()"
              cdkFocusInitial
              *ngIf="index < spotlightsTotal"
            >
              {{ 'general.next' | translate }}
            </button>
            <button
              mat-button
              (click)="onCloseClick()"
              cdkFocusInitial
              *ngIf="index === spotlightsTotal"
            >
              {{ 'general.close' | translate }}
            </button>
          </div>
        </div>
      </ng-container>
      <ds-spotlight-rocket
        *ngIf="animation === 'performance'"
      ></ds-spotlight-rocket>
      <ds-spotlight-santa
        *ngIf="animation === 'christmas'"
      ></ds-spotlight-santa>
    </mat-card-content>
  </mat-card>
</ng-template>
