import {
  Project,
  ProjectActions,
  ProjectState,
} from '../generated/model/project';

export const testProject: Project = {
  actions: [ProjectActions.Edit],
  has_offer: false,
  id: 1,
  company_bpm_id: 100,
  code: 'Test Code',
  created_on: new Date(),
  created_by: 'Test Created By',
  model: 'Test Model',
  project_volume: 1234234,
  name: 'Test Name',
  comment: 'Test Comment',
  chance: 50,
  customer_name: 'Test Customer Name',
  customer_number: 'Test Customer Number',
  state: ProjectState.InProgress,
  industry_id: 'Test Industry Id',
  industry_name: 'Test Industry Name',
  closed_date: new Date(),
  external_id: 'Test External Id',
  external_log_id: 'Test External Log Id',
  is_whole_sale_order: false,
  external_customer_number: 'Test External Customer Number',
};
