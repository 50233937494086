<div fxLayout="column" class="overflow-auto" #carousel>
  <div fxLayout="row">
    <div class="carousel-left-button" *ngIf="slidesNumber.length > 1">
      <button mat-raised-button color="primary" (click)="previousSlide()">
        <mat-icon> chevron_left</mat-icon>
      </button>
    </div>
    <div class="carousel-right-button" *ngIf="slidesNumber.length > 1">
      <button mat-raised-button color="primary" (click)="nextSlide()">
        <mat-icon> chevron_right</mat-icon>
      </button>
    </div>
    <div class="overflow-hidden">
      <div
        id="fadeAnimation"
        fxLayout="row"
        [style.transform]="offset"
        [fxLayoutGap]="1 | dsSpacing"
      >
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
<ol class="carousel-indicators" *ngIf="slidesNumber.length > 1">
  <li
    *ngFor="let index of slidesNumber"
    [ngClass]="{ 'active-slide': index === currentSlide / numberOfCards }"
    (click)="slideTo(index)"
  ></li>
</ol>
