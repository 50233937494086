import { OfferBaseInfo } from '../generated/model/offerBaseInfo';

export const testOfferBaseInfo: OfferBaseInfo = {
  /**
   * Product line
   */

  product_line: 'Test product_line',
  /**
   * Product icon name (not url)
   */

  product_icon_name: 'Test product_icon_name',
  /**
   * Crane name
   */

  crane_name: 'Test crane_name',
  /**
   * Offer number
   */

  offer_number: 'Test offer_number',
  /**
   * Creation date
   */

  creation_date: new Date().toString(),
  /**
   * Reference 1
   */

  reference1: 'Test reference1',
  /**
   * Reference 2
   */

  reference2: 'Test reference2',
  /**
   * Payment term id
   */

  payment_term_id: 1,
  /**
   * Main image url
   */

  main_image_url: 'Test main_image_url',
  /**
   * Offer (Cart) Valid until date string
   */

  valid_until: 'Test valid_until',
};
