/**
 * SalesSolutions Backend
 * Collection of APIs for SalesSolutions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CalculationItemPerspective } from './calculationItemPerspective';


/**
 * This class represents a calculation item.
 */
export interface CalculationItem { 
    /**
     * Cart item id
     */
    
    id?: string | null;
    /**
     * A link to the N:Palfinger.Configurator.Project.Services.Models.CartItem.  This will be `null` in future.
     */
    
    cart_item_id?: number | null;
    /**
     * The id of the Palfinger.Configurator.Data.Entities.CartItemGroup.
     */
    
    cart_item_group_id?: number | null;
    /**
     * Configuration options
     */
    
    items?: Array<CalculationItem> | null;
    /**
     * Cart item name
     */
    
    name?: string | null;
    /**
     * Cart item description
     */
    
    description?: string | null;
    /**
     * Cart item quantity
     */
    
    quantity: number;
    /**
     * Calculation Item type
     */
    
    item_type: CalculationItemItemType;
    /**
     * Items, which will be offered as optional item
     */
    
    is_optional: boolean;
    /**
     * Whether cart item is not discountable
     */
    
    is_read_only: boolean;
    /**
     * Show, if custom article is PPI Relevant
     */
    
    is_ppi_relevant: boolean;
    
    general_agent?: CalculationItemPerspective;
    
    partner?: CalculationItemPerspective;
    
    customer?: CalculationItemPerspective;
    /**
     * Contract item id
     */
    
    contract_item_id?: string | null;
    /**
     * CustomArticle item id
     */
    
    custom_article_item_id?: string | null;
    /**
     * Mcc item id
     */
    
    mcc_item_id?: string | null;
    /**
     * Product item id
     */
    
    product_item_id?: string | null;
    /**
     * SalesOptionI item id
     */
    
    sales_option_item_id?: string | null;
    /**
     * TuningCenter item id
     */
    
    tuning_center_item_id?: string | null;
    /**
     * WarrantyExtension item id
     */
    
    warranty_extension_item_id?: string | null;
}
export enum CalculationItemItemType {
    Item = 'Item',
    Option = 'Option',
    Group = 'Group',
    GroupItem = 'GroupItem'
};



