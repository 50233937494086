/**
 * SalesSolutions Backend
 * Collection of APIs for SalesSolutions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Offer Price Position (one price line in the offer document)
 */
export interface OfferPricePosition { 
    /**
     * Name
     */
    
    name?: string | null;
    /**
     * Quantity
     */
    
    quantity: number;
    /**
     * Description
     */
    
    description?: string | null;
    /**
     * Discount
     */
    
    discount?: string | null;
    /**
     * Price
     */
    
    price?: string | null;
    /**
     * Some of the price positions have sub positions (in practice usually Equipments, but in future it could also be used for Cranes)
     */
    
    options?: Array<OfferPricePosition> | null;
    /**
     * Type of the item
     */
    
    type: OfferPricePositionType;
}
export enum OfferPricePositionType {
    CartItem = 'CartItem',
    ConfigurationOption = 'ConfigurationOption',
    Group = 'Group'
};



