/**
 * SalesSolutions Backend
 * Collection of APIs for SalesSolutions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The object to represent the custom article price list.
 */
export interface CustomArticlePriceList { 
    /**
     * The id of the custom article price list.
     */
    
    id: number;
    /**
     * The id of the custom article category.
     */
    
    category_id: number;
    /**
     * The name of the custom article category.
     */
    
    category_name?: string | null;
    /**
     * The name of the custom article price list.
     */
    
    name?: string | null;
    /**
     * The access level of the custom article price list.
     */
    
    access_level: CustomArticlePriceListAccessLevel;
}
export enum CustomArticlePriceListAccessLevel {
    Private = 'Private',
    Internal = 'Internal',
    Public = 'Public'
};



