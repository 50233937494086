/**
 * SalesSolutions Backend
 * Collection of APIs for SalesSolutions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompareDifference } from './compareDifference';


/**
 * Represents a Data Transfer Object (DTO) for comparing configuration options.
 */
export interface ConfigurationOptionCompare { 
    /**
     * The name of the configuration option.
     */
    
    name?: string | null;
    /**
     * The URL of the image representing the configuration option.
     */
    
    image_url?: string | null;
    /**
     * The type of differences found in the comparison of the configuration option.
     */
    
    compare_difference_type: ConfigurationOptionCompareCompareDifferenceType;
    /**
     * A short description of the configuration option.
     */
    
    short_description?: string | null;
    /**
     * A long description of the configuration option.
     */
    
    long_description?: string | null;
    /**
     * The sales price of the configuration option.
     */
    
    sales_price: number;
    
    changes?: CompareDifference;
}
export enum ConfigurationOptionCompareCompareDifferenceType {
    Added = 'Added',
    Changed = 'Changed',
    Removed = 'Removed',
    Unchanged = 'Unchanged'
};



