import {
  PriceListOptionChange,
  PriceListOptionChangeState,
} from '../generated/model/priceListOptionChange';

export const testSalesPriceListOptionChange: PriceListOptionChange = {
  name: 'Test Name',
  state: PriceListOptionChangeState.SELECTABLE,
  value: 'Test Value',
};
export const testSalesPriceListOptionChange2: PriceListOptionChange = {
  name: 'Test Name 2',
  state: PriceListOptionChangeState.SELECTED,
  value: 'Test Value 2',
};
