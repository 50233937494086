/**
 * TurnKey Solutions shared
 * TurnKey Solutions API for the shared module
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum SectionType {
    Questionnaire = 'Questionnaire',
    Configuration = 'Configuration'
};

