import { Action, createReducer, on } from '@ngrx/store';
import { UserActions } from './user.actions';

export interface UserState {
  isEarlyAccessRequested?: boolean;
}

export const initialUserState: UserState = {};

const reducer = createReducer(
  initialUserState,
  on(UserActions.GetEarlyAccessRequestedSuccess, (state, { isRequested }) => ({
    ...state,
    isEarlyAccessRequested: isRequested,
  })),
  on(UserActions.RequestEarlyAccessSuccess, (state) => ({
    ...state,
    isEarlyAccessRequested: true,
  })),
);

export function userReducer(state: UserState | undefined, action: Action) {
  return reducer(state, action);
}
