import { CalculationPerspective } from '../generated/model/calculationPerspective';

export const testCalculationPerspective: CalculationPerspective = {
  relative_discount: 0.1,
  absolute_discount: 2,
  transport_costs: 3,
  purchase_price: 4,
  sales_price: 5,
  final_price: 6,
  discountable_purchase_price: 7,
  discountable_sales_price: 8,
  average_discount: 9,
  absolute_margin: 10,
  relative_margin: 11,
  ppi_factor: 12,
  ppi_price: 13,
  average_discount_excluding_net: 14,
};
