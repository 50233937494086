import { CommonModule } from '@angular/common';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DsCardCarouselComponent } from '@design-system/components/card-carousel';
import { Answer } from '@sales-libs/sc-questionnaire/data-access';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import {
  MediaType,
  SlScDescriptionDecorationPipe,
} from '@sales-libs/shared/util';
import { MatButtonModule } from '@angular/material/button';

export const imports = [
  CommonModule,
  TranslateModule,
  DsCardCarouselComponent,
  MatCardModule,
  MatButtonModule,
  MatDialogModule,
  SlScDescriptionDecorationPipe,
];

@Component({
  selector: 'sl-sc-more-details-dialog',
  standalone: true,
  imports,
  templateUrl: './more-details-dialog.component.html',
  styleUrls: ['./more-details-dialog.component.scss'],
})
export class SlScMoreDetailsDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public answer: Answer) {}

  @ViewChild('videoPlayer') videoplayer: ElementRef;
  readonly AnswerDetailsMediaType = MediaType;

  playVideo() {
    this.videoplayer?.nativeElement.play();
  }
}
