import { CompareDifference } from '../generated/model/compareDifference';

export const testCompareDifference: CompareDifference = {
  name: 'Test Name',
  short_description: 'Test Short Description',
  long_description: 'Test Long Description',
  sales_price: 0,
  original_sales_price: 10,
  comparer_name: 'Test Comparer Name',
};
