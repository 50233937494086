/**
 * SalesSolutions Backend
 * Collection of APIs for SalesSolutions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CalculationPerspective } from './calculationPerspective';
import { CalculationItem } from './calculationItem';


/**
 * Calculation of a cart.
 */
export interface Calculation { 
    /**
     * Cart id
     */
    
    id: number;
    /**
     * A possible offer id to this calculation.
     */
    
    offer_id: number;
    /**
     * the used items for the calculation.
     */
    
    items: Array<CalculationItem>;
    /**
     * Whether calculation has been locked.
     */
    
    is_read_only: boolean;
    
    general_agent?: CalculationPerspective;
    
    partner: CalculationPerspective;
    
    customer: CalculationPerspective;
    /**
     * Available actions
     */
    
    readonly actions?: Array<CalculationActions> | null;
}
export enum CalculationActions {
    CreateOffer = 'CreateOffer',
    ViewOffer = 'ViewOffer'
};



