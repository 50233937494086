import {
  ContractItemInput,
  ContractItemInputConnectedType,
  ContractItemInputContractType,
  ContractItemInputOilType,
  ContractItemInputPaymentPeriodType,
} from '../generated';
import { testServiceContract } from './serviceContract';

export const testContractItemInput: ContractItemInput = {
  id: 'testId',
  cart_id: 789,
  sales_price: 123.45,
  purchase_price: 678.9,
  name: 'testName',
  connected_type: ContractItemInputConnectedType.Data,
  payment_period_type: ContractItemInputPaymentPeriodType.Monthly,
  contract_type: ContractItemInputContractType.Pure,
  oil_type: ContractItemInputOilType.Premium,
  service_contract: testServiceContract,
  description: 'testDescription',
  sort_key: 0,
  sort_key_optional: 0,
  is_optional: false,
};
