import {
  SalesPriceListOption,
  SalesPriceListOptionState,
} from '../generated/model/salesPriceListOption';
import { testConfigurationMedia } from './configurationMedia';
import { testSalesPriceListOptionMappingValue } from './salesPriceListOptionMappingValue';

export const testSalesPriceListOption: SalesPriceListOption = {
  name: 'Test SalesPriceListOption Name',
  price: 10101,
  purchase_price: 2,
  available_values: [testSalesPriceListOptionMappingValue],
  value: 'Test SalesPriceListOption Value',
  image: testConfigurationMedia,
  gallery: [testConfigurationMedia],
  technical_description: 'Test SalesPriceListOption Description',
  customer_value: 'Test SalesPriceListOption Customer Value',
  description: 'Test SalesPriceListOption Description',
  comment: 'Test SalesPriceListOption Comment',
  precondition: ['Test SalesPriceListOption Precondition'],
  disabled_by: ['Test SalesPriceListOption Disabled By'],
  error_messages: ['Test SalesPriceListOption Error Message'],
  state: SalesPriceListOptionState.SELECTABLE,
  is_upselling_option: false,
  sort_key_sap: 0,
  material_id: 'material_id',
  is_inherited: false,
  epp: 'test epp',
};
