<div class="container">
  <div class="rocketContainer">
    <div class="tip"></div>
    <div class="rocket"></div>
    <div class="window"></div>
    <div class="dots"></div>
    <div class="bum"></div>
    <div class="wing wingOne"></div>
    <div class="wing wingTwo"></div>
    <div class="light"></div>
    <div class="light2"></div>
    <div class="flame"></div>
    <div class="flame2"></div>
  </div>
</div>
