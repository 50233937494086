import { NgModule } from '@angular/core';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { ScSharedState, scSharedReducer } from './sc-shared/sc-shared.reducer';

export interface ScSharedModuleState {
  scShared: ScSharedState;
}

export const ScSharedReducers: ActionReducerMap<ScSharedModuleState, any> = {
  scShared: scSharedReducer,
};

@NgModule({
  imports: [StoreModule.forFeature('scSharedModule', ScSharedReducers, {})],
})
export class ScSharedStoreModule {}
