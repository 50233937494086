/**
 * SalesSolutions Backend
 * Collection of APIs for SalesSolutions
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  OfferPricePosition,
  OfferPricePositionType,
} from '../generated/model/offerPricePosition';

/**
 * Offer Price Position (one price line in the offer document)
 */
export const testOfferPricePosition: OfferPricePosition = {
  /**
   * Name
   */

  name: 'Test name',
  /**
   * Quantity
   */

  quantity: 1,
  /**
   * Description
   */

  description: 'Test description',
  /**
   * Discount
   */

  discount: 'Test discount',
  /**
   * Price
   */

  price: 'Test price',
  /**
   * Some of the price positions have sub positions (in practice usually Equipments, but in future it could also be used for Cranes)
   */

  options: [],
  /**
   * Type of the item
   */

  type: OfferPricePositionType.CartItem,
};

/**
 * Offer Price Position (one price line in the offer document)
 */
export const testOfferPricePositionWithOptions: OfferPricePosition = {
  /**
   * Name
   */

  name: 'Test name 2',
  /**
   * Quantity
   */

  quantity: 2,
  /**
   * Description
   */

  description: 'Test description 2',
  /**
   * Discount
   */

  discount: 'Test discount 2',
  /**
   * Price
   */

  price: 'Test price 2',
  /**
   * Some of the price positions have sub positions (in practice usually Equipments, but in future it could also be used for Cranes)
   */

  options: [testOfferPricePosition],
  /**
   * Type of the item
   */

  type: OfferPricePositionType.ConfigurationOption,
};
