import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  CrmAccountResponse,
  UpsertAccountRequestAccountStatus,
  UpsertAccountRequestAccountType,
} from '@data-access/bpm-generated';
import { Store } from '@ngrx/store';
import { filterTruthy } from '@shared-lib/rxjs';
import { nameofFactory } from '@utils/name-of';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { CrmCustomerActions } from '../shared/store/crm-customer.actions';
import { CrmCustomerSelectors } from '../shared/store/crm-customer.selectors';

const nameof = nameofFactory<CrmAccountResponse>();

@Component({
  selector: 'pd-crm-order-confirmation-dialog',
  templateUrl: './crm-order-confirmation-dialog.component.html',
  styleUrls: ['./crm-order-confirmation-dialog.component.scss'],
})
export class CrmOrderConfirmationDialogComponent {
  form = new FormGroup({
    street_name: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    house_number: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });
  isLoading$: Observable<boolean>;
  readonly nameof = nameof;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CrmAccountResponse,
    private _dialogRef: MatDialogRef<CrmOrderConfirmationDialogComponent>,
    private _store: Store<any>,
  ) {
    this.isLoading$ = this._store.select(CrmCustomerSelectors.isLoading);
  }

  updateCustomer() {
    this._store.dispatch(
      CrmCustomerActions.UpsertCustomer({
        payload: {
          ...this.data,
          city: this.data.city ?? '',
          postal_code: this.data.postal_code ?? '',
          account_type: this.data.account_type
            ? UpsertAccountRequestAccountType[this.data.account_type]
            : undefined,
          account_status: this.data.account_status
            ? UpsertAccountRequestAccountStatus[this.data.account_status]
            : undefined,
          name: this.data.company_name,
          name2: this.data.company_name2,
          ...this.form.value,
          street:
            this.form.value.street_name + ' ' + this.form.value.house_number,
        },
      }),
    );

    this._store
      .select(CrmCustomerSelectors.upsertResult)
      .pipe(filterTruthy(), take(1))
      .subscribe(() => {
        this._dialogRef.close(true);
      });
  }
}
