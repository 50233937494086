import { SalesOptionCartItem } from '../generated';

export const testSalesOptionCartItem: SalesOptionCartItem = {
  is_optional: true,
  original_sales_price: 49.99,
  picture_name: 'testPictureName',
  is_edited: false,
  cart_item_price_comparer_id: '1',
  id: 'testSalesOptionItemId',
  cart_id: 56789,
  sales_option_name: 'AOS',
  name: 'testSalesOptionItemName',
  short_description: 'testShortDescription',
  long_description: 'testLongDescription',
  sales_price: 39.99,
  purchase_price: 29.99,
  quantity: 2,
  is_discountable: true,
  is_ppi_relevant: true,
  cart_item_group_id: 2,
  sort_key: 0,
  sort_key_optional: 0,
};
