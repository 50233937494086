import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { Answer } from '@sales-libs/sc-questionnaire/data-access';
import { SlScDescriptionDecorationPipe } from '@sales-libs/shared/util';

export const imports = [
  CommonModule,
  TranslateModule,
  MatButtonModule,
  MatDialogModule,
  SlScDescriptionDecorationPipe,
];

@Component({
  selector: 'sl-sc-model-description-dialog',
  standalone: true,
  imports,
  templateUrl: './model-description-dialog.component.html',
  styleUrls: ['./model-description-dialog.component.scss'],
})
export class SlScModelDescriptionDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public answer: Answer) {}
}
