import { ProductDetails } from '../generated/model/productDetails';
import { testAttribute } from './attribute';
import { testHighlight } from './highlight';
import { testLink } from './link';
import { testMedia } from './media';
import { testVariant } from './variant';

export const testProductDetails: ProductDetails = {
  description: 'Test ProductDetails Description',
  highlights: [testHighlight],
  categories: [testLink],
  brochures: [testMedia],
  job_reports: [testMedia],
  default_title_image_detail: testMedia,
  default_drawing: testMedia,
  default_arm_drawing: testMedia,
  variants: [testVariant],
  default_mediagallery: [testMedia],
  language_mediagallery: [testMedia],
  attributes: {
    attribute1: testAttribute,
    attribute2: {
      ...testAttribute,
      name: 'Test ProductDetails Attribute Name 2',
      data: { other: 'Data' },
    },
  },
  id: 'Test ProductDetails Id',
  name: 'Test ProductDetails Name',
  code: 'Test ProductDetails',
  model_id: 'Test ProductDetails Id',
  default_title_image: testMedia,
  rank: 1,
  object_type: 'Test ProductDetails Object Type',
  is_configurable: false,
};
