import {
  ConfigurationOptionCompare,
  ConfigurationOptionCompareCompareDifferenceType,
} from '../generated/model/configurationOptionCompare';
import { testCompareDifference } from './compareDifference';

export const testConfigurationOptionCompare: ConfigurationOptionCompare = {
  name: 'Test Name',
  image_url: 'Test Image Url',
  compare_difference_type:
    ConfigurationOptionCompareCompareDifferenceType.Changed,
  short_description: 'Test Short Description',
  long_description: 'Test Long Description',
  sales_price: 0,
  changes: testCompareDifference,
};
