import {
  SharedConfiguration,
  SharedConfigurationProductType,
} from '../generated/model/sharedConfiguration';

export const testSharedConfiguration: SharedConfiguration = {
  model_id: 'test model id',

  object_type: 'test object type',

  pricelist_date: '2018-01-01',

  product_type: SharedConfigurationProductType.Product,
  product_id: 1,
  product_line: 'Loader Cranes',
  options: {
    ['test option name']: 'test option value',
    ['test option name 2']: 'test option value 2',
  },
};
