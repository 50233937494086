/**
 * SalesSolutions Backend
 * Collection of APIs for SalesSolutions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The class represents the project update data.
 */
export interface ProjectUpdate { 
    /**
     * The name of the project.
     */
    
    name: string;
    /**
     * The comment of the project.
     */
    
    comment?: string | null;
    /**
     * The chance of the project.
     */
    
    chance: number;
    /**
     * The customer name of the project.
     */
    
    customer_name?: string | null;
    /**
     * The customer number of the project.
     */
    
    customer_number?: string | null;
    /**
     * The external customer number of the project.
     */
    
    external_customer_number?: string | null;
    /**
     * The state of the project.
     */
    
    state?: ProjectUpdateState | null;
    /**
     * The industry id to the project.
     */
    
    industry_id?: string | null;
    /**
     * The industry name to the project.
     */
    
    industry_name?: string | null;
    /**
     * The end date of the project.
     */
    
    closed_date?: Date | null;
    /**
     * Identifies, if the project is a whole sale order.
     */
    
    is_whole_sale_order: boolean;
}
export enum ProjectUpdateState {
    InProgress = 'InProgress',
    Offer = 'Offer',
    Won = 'Won',
    Lost = 'Lost',
    Closed = 'Closed'
};



