import { ConfigurationPackage } from '../generated';
import { testConfigurationPackageOption } from './configurationPackageOption';

export const testConfigurationPackage: ConfigurationPackage = {
  package_id: 'testPackageId',
  object_type: 'testObjectType',
  model_id: 'testModelId',
  purchase_price: '1',
  sales_price: '2',
  sort_key: 0,
  description: 'testDescription',
  options: [testConfigurationPackageOption],
  package_icon: 'testPackageIcon',
  fulfilled: true,
};
