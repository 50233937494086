import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adapter, CrmCustomerState } from './crm-customer.reducer';

const moduleState =
  createFeatureSelector<CrmCustomerState>('crmCustomerModule');

// get the selectors
const { selectAll } = adapter.getSelectors();

export namespace CrmCustomerSelectors {
  export const list = createSelector(moduleState, (state) => selectAll(state));
  export const filter = createSelector(moduleState, (state) => state.filter);
  export const isLoading = createSelector(
    moduleState,
    (state) => state.isLoading,
  );

  export const segments = createSelector(
    moduleState,
    (state) => state.segments,
  );

  export const isUnused = createSelector(
    moduleState,
    (state) => state.isUnused,
  );

  export const one = (accountId: string) =>
    createSelector(moduleState, (state) => state.entities[accountId]);

  export const isOneLoading = (accountId: string) =>
    createSelector(
      moduleState,
      (state) => state.loadingLookup && state.loadingLookup[accountId],
    );

  export const upsertResult = createSelector(
    moduleState,
    (state) => state.upsertResult,
  );
}
