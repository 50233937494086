import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

type stringType = string | null | undefined;

@Injectable({
  providedIn: 'root',
})
export class MetaTagsService {
  defaultMetaData = {
    description: 'Palfinger Solutions Configurator',
    title: 'PALFINGER – Solutions Configurator',
    keywords:
      'Palfinger, Solutions, Truck, Configurator, Food Logistics, Transport Logistics, Waste Management, Emergency Services, Local Authorities, Leasing, Rental Business',
  };

  constructor(
    private _titleService: Title,
    private _metaService: Meta,
  ) {}

  setMetaTitleDescriptionKeywords(
    title: stringType,
    description: stringType = '',
    keywords: stringType = '',
  ) {
    if (title) this._titleService.setTitle(title || this.defaultMetaData.title);
    if (description)
      this._metaService.updateTag({
        name: 'description',
        content: description || this.defaultMetaData.description,
      });
    if (keywords)
      this._metaService.updateTag({
        name: 'keywords',
        content: keywords || this.defaultMetaData.keywords,
      });
  }
}
