import {
  animate,
  animateChild,
  group,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component } from '@angular/core';
import { dsConfig } from '@design-system/cdk/config';

export const routeAnimations = trigger('routeAnimations', [
  transition('* <=> *', [
    style({
      position: 'relative',
      display: 'block',
      height: '100%',
      overflow: 'hidden',
    }),
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '99%',
          height: '100%',
        }),
      ],
      { optional: true },
    ),
    query(':enter', [style({ opacity: 0, top: 2 * dsConfig.spacing })], {
      optional: true,
    }),
    query(':leave', animateChild(), { optional: true }),
    group([
      query(':leave', [animate('150ms ease-out', style({ opacity: 0 }))], {
        optional: true,
      }),
      query(
        ':enter',
        [animate('250ms 150ms ease-out', style({ opacity: 1, top: 0 }))],
        { optional: true },
      ),
    ]),
    query(':enter', animateChild(), { optional: true }),
  ]),
]);

@Component({
  selector: 'sl-shared-router-container',
  templateUrl: './router-container.component.html',
  animations: [routeAnimations],
  standalone: true,
})
export class SlRouterContainerComponent {
  animateHelper: boolean;

  animate() {
    this.animateHelper = !this.animateHelper;
  }
}
