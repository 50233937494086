<h1 mat-dialog-title>
  {{ 'sales_shared.solution.model_description' | translate }}
</h1>
<div mat-dialog-content>
  <p [innerHTML]="answer.description | slScDescriptionDecoration"></p>
  <ul *ngIf="answer.details" class="model-details">
    <li
      *ngFor="let detail of answer.details"
      [innerHtml]="detail | slScDescriptionDecoration"
    ></li>
  </ul>
  <hr />
  <p>{{ answer.subheading }}</p>
</div>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>
    {{ 'general.close' | translate }}
  </button>
</mat-dialog-actions>
