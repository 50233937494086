import {
  AnimationTriggerMetadata,
  animate,
  query,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';

const slAnimationTiming = '400ms cubic-bezier(0.25, 0, 0.25, 1)';
const transformScaleHalf = 'scale(0.5)';
const transformScaleFull = 'scale(1)';

export const slAnimations: {
  readonly tableRow: AnimationTriggerMetadata;
  readonly list: AnimationTriggerMetadata;
  readonly table: AnimationTriggerMetadata;
} = {
  tableRow: trigger('tableRow', [
    transition(':enter', [
      style({ transform: 'translate3d(100%, 0, 0)' }),
      animate(slAnimationTiming, style({ transform: 'none' })),
    ]),
    transition(':leave', [
      style({ transform: 'none' }),
      animate(
        slAnimationTiming,
        style({ transform: 'translate3d(100%, 0, 0)' }),
      ),
    ]),
  ]),
  list: trigger('list', [
    transition('* <=> *', [
      query(
        ':enter',
        [
          style({ transform: transformScaleHalf, opacity: 0 }),
          stagger(
            '100ms',
            animate(
              slAnimationTiming,
              style({ transform: transformScaleFull, opacity: 1 }),
            ),
          ),
        ],
        { optional: true },
      ),
    ]),
  ]),
  table: trigger('table', [
    transition('* <=> *', [
      query(
        'tr:enter',
        [
          style({ opacity: 0 }),
          stagger('75ms', animate(slAnimationTiming, style({ opacity: 1 }))),
        ],
        { optional: true },
      ),
    ]),
  ]),
};
