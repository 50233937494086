/**
 * SalesSolutions Backend
 * Collection of APIs for SalesSolutions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceContract } from './serviceContract';


/**
 * The class for a cart item dto creation based on a contract item.
 */
export interface ContractItemInput { 
    /**
     * Name of the item.
     */
    
    name?: string | null;
    /**
     * Gets or sets the type of connected type for this contract item.
     */
    
    connected_type?: ContractItemInputConnectedType | null;
    /**
     * Gets or sets the payment period type for this contract item.
     */
    
    payment_period_type?: ContractItemInputPaymentPeriodType | null;
    /**
     * Gets or sets the contract type of this contract item.
     */
    
    contract_type?: ContractItemInputContractType | null;
    /**
     * Gets or sets the contract type of this contract item.
     */
    
    description: string;
    /**
     * Gets or sets the oil type associated with this contract item.
     */
    
    oil_type?: ContractItemInputOilType | null;
    
    service_contract?: ServiceContract;
    /**
     * Sorting by FE.
     */
    
    sort_key: number;
    /**
     * Sorting by FE for optional items.
     */
    
    sort_key_optional: number;
    /**
     * Cart item of the target cart item which should be compared to the actual one in regards to the price.
     */
    
    cart_item_price_comparer_id?: string | null;
    /**
     * Id of the item.  If null, the item is a new Palfinger.Configurator.Project.Services.Models.CartItem.ContractItemDto.
     */
    
    id?: string | null;
    /**
     * Cart id of the parent cart for this item.
     */
    
    cart_id: number;
    /**
     * Customizable sales price of the item.
     */
    
    sales_price: number;
    /**
     * Purchase price of the item.
     */
    
    purchase_price: number;
    /**
     * Internal cpq value
     */
    
    epp?: string | null;
    /**
     * Flags, if an item is marked as optional.
     */
    
    is_optional: boolean;
}
export enum ContractItemInputConnectedType {
    Data = 'Data',
    Plus = 'Plus',
    Premium = 'Premium'
};
export enum ContractItemInputPaymentPeriodType {
    Monthly = 'Monthly',
    Quarterly = 'Quarterly',
    Yearly = 'Yearly',
    OneOffPayment = 'OneOffPayment'
};
export enum ContractItemInputContractType {
    Pure = 'Pure',
    Perform = 'Perform',
    Pro = 'Pro',
    Prime = 'Prime'
};
export enum ContractItemInputOilType {
    Premium = 'Premium',
    Bio = 'Bio',
    Extreme = 'Extreme'
};



