import { createAction, props } from '@ngrx/store';

export namespace UserActions {
  // Request Early Access
  export const RequestEarlyAccess = createAction('[User] REQUEST_EARLY_ACCESS');
  export const RequestEarlyAccessSuccess = createAction(
    '[User] REQUEST_EARLY_ACCESS_SUCCESS',
  );
  export const RequestEarlyAccessError = createAction(
    '[User] REQUEST_EARLY_ACCESS_ERROR',
  );

  // Check Early Access Requested
  export const GetEarlyAccessRequested = createAction(
    '[User] GET_EARLY_ACCESS_REQUESTED',
  );
  export const GetEarlyAccessRequestedSuccess = createAction(
    '[User] GET_EARLY_ACCESS_REQUESTED_SUCCESS',
    props<{ isRequested: boolean }>(),
  );
  export const GetEarlyAccessRequestedError = createAction(
    '[User] GET_EARLY_ACCESS_REQUESTED_ERROR',
  );
}
