import {
  ContractItem,
  ContractItemConnectedType,
  ContractItemContractType,
  ContractItemOilType,
  ContractItemPaymentPeriodType,
} from '../generated';
import { testServiceContract } from './serviceContract';

export const testContractItem: ContractItem = {
  is_discountable: true,
  is_edited: true,
  company_bpm_id: 9876,
  original_sales_price: 543.21,
  quantity: 5,
  cart_item_price_comparer_id: '12345',
  sort_key: 2,
  cart_item_group_id: 456,
  is_optional: true,
  id: 'testId',
  cart_id: 789,
  sales_price: 123.45,
  purchase_price: 678.9,
  name: 'testName',
  connected_type: ContractItemConnectedType.Plus,
  payment_period_type: ContractItemPaymentPeriodType.Monthly,
  contract_type: ContractItemContractType.Perform,
  oil_type: ContractItemOilType.Bio,
  service_contract: testServiceContract,
  description: 'testDescription',
  sort_key_optional: 0,
};
