/**
 * SalesSolutions Backend
 * Collection of APIs for SalesSolutions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Represents a Data Transfer Object (DTO) for comparing package options.
 */
export interface PackageOptionCompare { 
    /**
     * The identifier of the associated package.
     */
    
    package_id: number;
    /**
     * The identifier of the sales option associated with the package.
     */
    
    sales_option_id?: string | null;
    /**
     * The type of differences found during the comparison of package options.
     */
    
    compare_difference_type: PackageOptionCompareCompareDifferenceType;
}
export enum PackageOptionCompareCompareDifferenceType {
    Added = 'Added',
    Changed = 'Changed',
    Removed = 'Removed',
    Unchanged = 'Unchanged'
};



