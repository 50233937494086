/**
 * ProductConfigurator Backend
 * ProductConfigurator Backend APIs
 *
 * The version of the OpenAPI document: product-internal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PackageCompare,
  PackageCompareCompareDifferenceType,
  PackageCompareType,
} from '../generated';
import { testCompareDifference } from './compareDifference';
import { testPackageOptionCompare } from './packageOptionCompare';

export const testPackageCompare: PackageCompare = {
  name: 'Test Name',
  object_type: 'Test Object Type',
  model_id: 'Test Model Id',
  short_description: 'Test Description',
  sales_price: 123,
  purchase_price: 456,
  fulfilled: false,
  compare_difference_type: PackageCompareCompareDifferenceType.Changed,
  type: PackageCompareType.Package,
  package_options: [testPackageOptionCompare],
  changes: testCompareDifference,
};
