export const formatUrlFriendlyName = (
  name: string | null | undefined,
): string | null => {
  if (!name?.length) {
    return null;
  }
  return removeSpecialChars(name).split(' ').join('_').toLowerCase();
};

export const deformatUrlFriendlyName = (
  name: string | null | undefined,
): string | null => {
  if (!name?.length) {
    return null;
  }
  return capitalizeFirstChar(name);
};

function capitalizeFirstChar(productLineName: string) {
  const parts = productLineName.split('_');
  for (let i = 0; i < parts.length; i++) {
    parts[i] = parts[i].charAt(0).toUpperCase() + parts[i].slice(1);
  }
  return parts.join(' ');
}

export const removeSpecialChars = (name: string | undefined): string => {
  if (!name) {
    return '';
  }
  return name.trim().replace(/[\\#,+()$~%.'":*?<>{}]/g, '');
};
