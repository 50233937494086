import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ScSharedModuleState } from '../shared-store.module';

export namespace ScSharedSelectors {
  export const sharedState =
    createFeatureSelector<ScSharedModuleState>('scSharedModule');

  export const userGuides = createSelector(
    sharedState,
    (state) => state?.scShared.userGuides?.data,
  );
  export const guide = createSelector(
    sharedState,
    (state) => state?.scShared.guide,
  );
  export const isLoading = createSelector(
    sharedState,
    (state) => state?.scShared.isLoading,
  );
  export const productLineName = createSelector(
    sharedState,
    (state) => state?.scShared.productLineName,
  );
  export const userGuid = createSelector(
    sharedState,
    (state) => state?.scShared.guide?.user_guid,
  );
  export const currentSection = createSelector(
    sharedState,
    (state) => state?.scShared.currentSection,
  );
  export const selectedLanguageCode = createSelector(
    sharedState,
    (state) => state?.scShared.selectedLanguageCode,
  );
  export const languages = createSelector(
    sharedState,
    (state) => state?.scShared.languages,
  );
  export const sections = createSelector(
    sharedState,
    (state) => state?.scShared.sections,
  );
  export const generalMetadata = createSelector(
    sharedState,
    (state) => state?.scShared.userGuides?.metadata,
  );
  export const modelSS = createSelector(sharedState, (state) => [
    state?.scShared.modelImage3D,
  ]);
}
