import {
  CrmAccountResponse,
  CrmSegmentsResponse,
  SearchAccountsResponse,
  UpsertAccountRequest,
} from '@data-access/bpm-generated';
import { createAction, props } from '@ngrx/store';
import { CrmCustomerFilter } from '../models';

export namespace CrmCustomerActions {
  export const LoadSegments = createAction('[CrmCustomer] LOAD_SEGMENTS');

  export const LoadSegmentsSuccess = createAction(
    '[CrmCustomer] LOAD_SEGMENTS_SUCCESS',
    props<{ payload: CrmSegmentsResponse }>(),
  );

  export const LoadSegmentsError = createAction(
    '[CrmCustomer] LOAD_SEGMENTS_ERROR',
  );

  export const FilterChange = createAction(
    '[CrmCustomer] FILTER_CHANGE',
    props<{ payload: CrmCustomerFilter }>(),
  );

  export const LoadCustomerList = createAction(
    '[CrmCustomer] LOAD_CUSTOMER_LIST',
    props<{ payload: CrmCustomerFilter }>(),
  );

  export const LoadCustomerListSuccess = createAction(
    '[CrmCustomer] LOAD_CUSTOMER_LIST_SUCCESS',
    props<{ payload: SearchAccountsResponse }>(),
  );

  export const LoadCustomerListError = createAction(
    '[CrmCustomer] LOAD_CUSTOMER_LIST_ERROR',
  );

  export const LoadCustomer = createAction(
    '[CrmCustomer] LOAD_CUSTOMER',
    props<{ payload: string }>(),
  );

  export const LoadCustomerSuccess = createAction(
    '[CrmCustomer] LOAD_CUSTOMER_SUCCESS',
    props<{ payload: CrmAccountResponse }>(),
  );

  export const LoadCustomerError = createAction(
    '[CrmCustomer] LOAD_CUSTOMER_ERROR',
    props<{ payload: string }>(),
  );

  export const UpsertCustomer = createAction(
    '[CrmCustomer] UPSERT_CUSTOMER',
    props<{
      payload: UpsertAccountRequest;
    }>(),
  );

  export const UpsertCustomerSuccess = createAction(
    '[CrmCustomer] UPSERT_CUSTOMER_SUCCESS',
    props<{ payload: CrmAccountResponse }>(),
  );

  export const UpsertCustomerError = createAction(
    '[CrmCustomer] UPSERT_CUSTOMER_ERROR',
    props<{ payload: UpsertAccountRequest }>(),
  );
}
