import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'slScMapPalfingerLang',
  standalone: true,
})
export class SlScMapPalfingerLangPipe implements PipeTransform {
  transform(palfinger_lang: string): string {
    switch (palfinger_lang) {
      case 'de':
        return 'de-de';
      case 'fr':
        return 'fr-fr';
      default:
        return 'en';
    }
  }
}
