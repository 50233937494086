import { Customer } from '../generated/model/customer';
import { testSegment } from './segment';

export const testCustomer: Customer = {
  id: 1,
  company_name: 'Test Company Name',
  company_name2: 'Test Second Company Name',
  customer_dealer_number: 'Test Dealer Number',
  customer_number: 'Test Customer Number',
  business_partner_number: 'Test Business Partner Nr',
  customer_since: 'Test Customer Since',
  email: 'test.email@mail.com',
  external_customer_number: 'TestExternal Customer Number',
  fax: 'Test Fac',
  industry_code: 'Test Industry Code',
  industry_key: 'Test Industry Key',
  language: 'en',
  mobile_phone_number: '+43 17234 2333',
  phone_number: '+49 2342 234234',
  url: 'www.test.com',
  country_code: 'AT',
  city: 'Salzburg',
  postal_code: '5233',
  region_code: 'Test Region Code',
  street_name: 'Test Street Name',
  street: 'Test Str. 7b',
  house_number: '5',
  first_name: 'Test First Name',
  last_name: 'Test Last Name',
  segments: [testSegment],
};
