import {
  CartItemCompare,
  CartItemCompareCartItemType,
  CartItemCompareCompareDifferenceType,
} from '../generated/model/cartItemCompare';
import { testCompareDifference } from './compareDifference';
import { testConfigurationOptionCompare } from './configurationOptionCompare';
import { testPackageCompare } from './packageCompare';

export const testCartItemCompare: CartItemCompare = {
  name: 'Test Name',
  image_url: 'Test Image Url',
  cart_item_type: CartItemCompareCartItemType.Crane,
  compare_difference_type: CartItemCompareCompareDifferenceType.Changed,
  short_description: 'Test Short Description',
  long_description: 'Test Long Description',
  sales_price: 0,
  original_sales_price: 10,
  comparer_name: 'Test Comparer Name',
  is_optional: true,
  is_edited: false,
  options: [testConfigurationOptionCompare],
  packages: [testPackageCompare],
  changes: testCompareDifference,
};
