import { formatUrlFriendlyName, removeSpecialChars } from './url-formatter';

export const populateUrlFriendlyProductLineEnum = (
  guides: any[],
): { [key: string]: string } => {
  if (!guides.length) {
    return {};
  }
  const mappingObject = {};
  guides.forEach((guide) => {
    mappingObject[guide.key] = formatUrlFriendlyName(guide.name);
  });

  return mappingObject;
};

export const populateProductLineEnum = (
  guides: any[],
  filtered = true,
): { [key: string]: string } => {
  if (!guides.length) {
    return {};
  }
  const mappingObject = {};
  guides.forEach((guide) => {
    mappingObject[guide.key] = filtered
      ? removeSpecialChars(guide.name)
      : guide.name;
  });

  return mappingObject;
};
