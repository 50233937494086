import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'slScDescriptionDecoration',
  standalone: true,
})
export class SlScDescriptionDecorationPipe implements PipeTransform {
  transform(description: string | null | undefined): string | null | undefined {
    if (description?.includes('style="text-decoration: underline;"')) {
      return description?.replace(
        new RegExp('style="text-decoration: underline;"', 'g'),
        'class="text-decoration-underline"',
      );
    }
    return description;
  }
}
