import { SalesPriceList, SalesPriceListState } from '../generated';
import { testSalesPriceListOptionCategory } from './salesPriceListOptionCategory';
import { testSalesPriceListOptionErrorAttribute } from './salesPriceListOptionErrorAttribute';
import { testSalesPriceListPackage } from './salesPricelistPackage';

export const testSalesPriceList: SalesPriceList = {
  id: 'Test Id',
  object_type: 'Test Object Type',
  configurable: false,
  categories: [testSalesPriceListOptionCategory],
  state: SalesPriceListState.VALID,
  error_attributes: [testSalesPriceListOptionErrorAttribute],
  packages: [testSalesPriceListPackage],
};
