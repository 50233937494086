import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SalestoolSharedModuleState } from '../salestool-shared-store.module';

const sharedState = createFeatureSelector<SalestoolSharedModuleState>(
  'salestoolSharedModule',
);

const userState = createSelector(sharedState, (state) => state?.user);

export namespace UserSelectors {
  export const isEarlyAccessRequested = createSelector(
    userState,
    (state) => state.isEarlyAccessRequested,
  );
}
