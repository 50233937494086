import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SalestoolSharedModuleState } from '../salestool-shared-store.module';

const moduleState = createFeatureSelector<SalestoolSharedModuleState>(
  'salestoolSharedModule',
);

export namespace CurrencySelectors {
  const currencyState = createSelector(moduleState, (state) => state?.currency);
  export const currency = createSelector(currencyState, (state) =>
    state ? state[state.scope] : null,
  );

  export const generalCurrency = createSelector(
    currencyState,
    (state) => state?.General,
  );
}
