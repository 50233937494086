import { style, animate, animation } from '@angular/animations';

const bezierAnimation = 'cubic-bezier(0.785, 0.135, 0.15, 0.86)';

export const fadeIn = animation([
  style({ opacity: 0 }), // start state
  animate(`{{time}} ${bezierAnimation}`, style({ opacity: 1 })),
]);

export const fadeOut = animation([
  animate(`{{time}} ${bezierAnimation}`, style({ opacity: 0 })),
]);
