import { CdkStepper } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  TemplateRef,
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
  MAT_MENU_DEFAULT_OPTIONS,
  MatMenuModule,
} from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SlSharedScNavigationStep } from './navigation-step.model';

export const imports = [
  CommonModule,
  FlexLayoutModule,
  MatProgressBarModule,
  MatButtonModule,
  MatIconModule,
  MatMenuModule,
];

@Component({
  selector: 'sl-shared-sc-navigation-stepper',
  standalone: true,
  imports,
  templateUrl: './navigation-stepper.component.html',
  styleUrls: ['./navigation-stepper.component.scss'],
  // This custom stepper provides itself as CdkStepper so that it can be recognized
  // by other components.
  providers: [
    {
      provide: CdkStepper,
      useExisting: SlSharedScNavigationStepperComponent,
    },
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'sl-sc-steps-menu' },
    },
  ],
})
export class SlSharedScNavigationStepperComponent
  extends CdkStepper
  implements AfterContentInit, OnChanges
{
  @Input() isNextButtonDisabled = true;
  @Input() scSteps: Array<SlSharedScNavigationStep>;

  // No cdkStepperNext and cdkStepperPrevious directives are used
  // instead control of the stepper is completely up to the host component
  // and done through the next() and previous() methods
  @Output() previousClicked: EventEmitter<void> = new EventEmitter();
  @Output() nextClicked: EventEmitter<void> = new EventEmitter();
  @Output() stepClicked: EventEmitter<string> = new EventEmitter<string>();

  nextLabelTemp: TemplateRef<any> | undefined;
  prevLabelTemp: TemplateRef<any> | undefined;

  ngAfterContentInit() {
    super.ngAfterContentInit();
    this.setStepLabels();
  }

  ngOnChanges(): void {
    this.setStepLabels();
  }

  setStepLabels(): void {
    // due to dynamically added steps into steps array, this needs to be pushed down the event loop
    // in order to get the refreshed steps state
    setTimeout(() => {
      if (this.selectedIndex !== 0) {
        this.prevLabelTemp = this.steps.get(
          this.selectedIndex - 1,
        )?.stepLabel?.template;
      }
      if (this.selectedIndex !== this.steps.length - 1) {
        this.nextLabelTemp = this.steps.get(
          this.selectedIndex + 1,
        )?.stepLabel?.template;
      }
    }, 0);
  }

  stepChanged(stepName: string): void {
    this.stepClicked.emit(stepName);
  }

  prevStep(): void {
    this.previousClicked.emit();
  }

  nextStep(): void {
    this.nextClicked.emit();
  }
}
