import {
  CartOverview,
  CartOverviewState,
} from '../generated/model/cartOverview';
import { testCurrency } from './currency';

export const testCartOverview: CartOverview = {
  id: 1,
  model: 'Test Model',
  locked: false,
  cart_image: 'Test Image',
  created_on: new Date(),
  version: 1,
  active: false,
  valid: false,
  state: CartOverviewState.Active,
  final_price: 1,
  sales_price: 1,
  project_id: 1,
  comment: 'Test Comment',
  price_list_date: new Date(),
  product_line: 'Test Product Line',
  language: 'en',
  currency_settings: testCurrency,
  valid_until: new Date(),
  modified_on: new Date(),
};
