import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  CrmAccountResponse,
  CrmSegmentItemResponse,
  UpsertAccountRequest,
  UpsertAccountRequestAccountStatus,
  UpsertAccountRequestAccountType,
} from '@data-access/bpm-generated';
import { FilterValue } from '@design-system/components/filter-input';
import { CountryFacade } from '@features/root-store';
import { Store } from '@ngrx/store';
import { filterTruthy } from '@shared-lib/rxjs';
import { nameofFactory } from '@utils/name-of';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CrmCustomerActions } from '../shared/store/crm-customer.actions';
import { CrmCustomerSelectors } from '../shared/store/crm-customer.selectors';

const nameOf = nameofFactory<UpsertAccountRequest>();

@Component({
  selector: 'pd-crm-customer-form-dialog',
  templateUrl: './crm-customer-form-dialog.component.html',
  styleUrls: ['./crm-customer-form-dialog.component.scss'],
})
export class CrmCustomerFormDialogComponent implements OnInit {
  customer?: CrmAccountResponse;
  form = new FormGroup({
    // meta
    name: new FormControl<string>('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    name2: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
    }),
    country_code: new FormControl<string>('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    city: new FormControl<string>('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    postal_code: new FormControl<string>('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    street_name: new FormControl<string>('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    house_number: new FormControl<string>('', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    // contact
    email: new FormControl<string | undefined>(undefined, {
      validators: [Validators.email],
      nonNullable: true,
    }),
    fax: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
    }),
    phone_number: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
    }),
    url: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
    }),
    market_ids: new FormControl<string[] | undefined>(undefined, {
      nonNullable: true,
    }),
  });
  countries$: Observable<FilterValue[] | undefined>;
  isLoading$: Observable<boolean>;
  segments$: Observable<CrmSegmentItemResponse[] | undefined>;

  readonly nameof = nameOf;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CrmAccountResponse,
    private _dialogRef: MatDialogRef<CrmCustomerFormDialogComponent>,
    private _store: Store<any>,
    private _countryFacade: CountryFacade,
  ) {
    this.form.get(nameOf('market_ids'))?.setValidators(Validators.required);

    this.customer = data;

    this.countries$ = this._countryFacade.getCountries().pipe(
      map((list) =>
        list.map((x) => ({
          value: x.country_code || '',
          viewValue: x.name || '',
        })),
      ),
    );

    this.isLoading$ = this._store.select(CrmCustomerSelectors.isLoading);

    this.segments$ = this._store.select(CrmCustomerSelectors.segments);
  }

  ngOnInit(): void {
    this._store.dispatch(CrmCustomerActions.LoadSegments());
  }

  upsertCustomer() {
    this._store.dispatch(
      CrmCustomerActions.UpsertCustomer({
        payload: {
          ...this.form.getRawValue(),
          account_status: UpsertAccountRequestAccountStatus.Prospect,
          account_type: UpsertAccountRequestAccountType.EndCustomer,
          street:
            this.form.value.street_name + ' ' + this.form.value.house_number,
        },
      }),
    );

    this._store
      .select(CrmCustomerSelectors.upsertResult)
      .pipe(filterTruthy(), take(1))
      .subscribe((result) => {
        this._dialogRef.close(result);
      });
  }
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface CustomerForm {
  // meta
  name: FormControl<string>;
  name2: FormControl<string | undefined>;
  country_code: FormControl<string>;
  city: FormControl<string>;
  postal_code: FormControl<string>;
  street_name: FormControl<string>;
  house_number: FormControl<string>;
  // contact
  email: FormControl<string | undefined>;
  fax: FormControl<string | undefined>;
  phone_number: FormControl<string | undefined>;
  url: FormControl<string | undefined>;
  market_ids: FormControl<string[] | undefined>;
}
