import { HttpStatusCode } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
  Guide,
  Guides,
  Languages,
  SharedService,
} from '@sales-libs/shared/data-access/sc-generated';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ScSharedActions } from './sc-shared.actions';

@Injectable()
export class ScSharedEffects {
  private actions$: Actions = inject(Actions);
  getUserGuides$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ScSharedActions.getUserGuides),
      switchMap((payload) =>
        this.sharedService.getGuides(payload.language).pipe(
          map((userGuidesPayload: Guides) =>
            ScSharedActions.getUserGuidesSuccess({
              userGuides: userGuidesPayload,
            }),
          ),
          catchError((err) =>
            of(
              ScSharedActions.getUserGuidesError({
                payload: err.error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  getGuide$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ScSharedActions.getGuide),
      switchMap((payload) =>
        this.sharedService
          .getUserGuide(
            payload.guideId,
            payload.language,
            payload.sectionType,
            payload.guid,
          )
          .pipe(
            map((guidePayload: Guide) =>
              ScSharedActions.getGuideSuccess({
                guide: guidePayload,
              }),
            ),
            catchError((err) => {
              if (err.status === HttpStatusCode.NotFound) {
                this._router.navigate(['special']);
              }
              return of(
                ScSharedActions.getGuideError({
                  payload: err.error,
                }),
              );
            }),
          ),
      ),
    ),
  );

  getLanguages$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ScSharedActions.getLanguages),
      switchMap((payload) =>
        this.sharedService.getLanguages(payload?.guideId).pipe(
          map((languagesPayload: Languages) =>
            ScSharedActions.getLanguagesSuccess({
              languages: languagesPayload,
            }),
          ),
          catchError((err) =>
            of(
              ScSharedActions.getLanguagesError({
                payload: err.error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  constructor(
    private sharedService: SharedService,
    private readonly _router: Router,
  ) {}
}
