import { createAction, props } from '@ngrx/store';
import { Section } from '@sales-libs/sc-questionnaire/data-access';
import {
  Guide,
  Guides,
  Languages,
  ProblemDetails,
  SectionType,
} from '@sales-libs/shared/data-access/sc-generated';

export namespace ScSharedActions {
  export const getUserGuides = createAction(
    '[SC_Shared] GET_USER_GUIDES',
    props<{ language: string }>(),
  );
  export const getUserGuidesSuccess = createAction(
    '[SC_Shared] GET_USER_GUIDES_SUCCESS',
    props<{ userGuides: Guides }>(),
  );
  export const getUserGuidesError = createAction(
    '[SC_Shared] GET_USER_GUIDES_ERROR',
    props<{ payload: ProblemDetails }>(),
  );
  export const getGuide = createAction(
    '[SC_Shared] GET_GUIDE',
    props<{
      guideId: number;
      language: string;
      sectionType: SectionType;
      guid: string | undefined;
    }>(),
  );
  export const getGuideSuccess = createAction(
    '[SC_Shared] GET_GUIDE_SUCCESS',
    props<{ guide: Guide }>(),
  );
  export const getGuideError = createAction(
    '[SC_Shared] GET_GUIDE_ERROR',
    props<{ payload: ProblemDetails }>(),
  );

  export const getLanguages = createAction(
    '[SC_Shared] GET_LANGUAGES',
    props<{ guideId?: number }>(),
  );
  export const getLanguagesSuccess = createAction(
    '[SC_Shared] GET_LANGUAGES_SUCCESS',
    props<{ languages: Languages }>(),
  );
  export const getLanguagesError = createAction(
    '[SC_Shared] GET_LANGUAGES_ERROR',
    props<{ payload: ProblemDetails }>(),
  );

  export const SetProductLineName = createAction(
    '[SC_Shared] SET_PRODUCT_LINE_NAME',
    props<{ productLineName: string }>(),
  );
  export const SetUserGuid = createAction(
    '[SC_Shared] SET_USER_GUID',
    props<{ userGuid: string }>(),
  );

  export const SetCurrentSection = createAction(
    '[SC_Shared] SET_CURRENT_SECTION',
    props<{ currentSection: Section | undefined }>(),
  );

  export const SetSelectedLanguageCode = createAction(
    '[SC_Shared] SET_SELECTED_LANGUAGE_CODE',
    props<{ selectedLanguageCode: string }>(),
  );

  export const SetGuide = createAction(
    '[SC_Shared] SET_SC_SHARED_GUIDE',
    props<{ guide: Guide }>(),
  );

  export const SetScSharedState = createAction(
    '[SC_Shared] SET_SC_SHARED_STATE',
    props<{
      productLineName: string;
      userGuid: string;
      currentSection: Section | undefined;
      selectedLanguageCode: string;
    }>(),
  );

  export const setLanguageAndProductLine = createAction(
    '[SC_Shared] SET_LANGUAGE_AND_PRODUCT_LINE',
    props<{ selectedLanguageCode: string; productLineName: string }>(),
  );

  export const save3DModelImage = createAction(
    '[SC_Shared] SAVE_3D_MODEL_IMAGE',
    props<{ modelImage3D: string | undefined }>(),
  );

  export const resetState = createAction('[SC_Shared] RESET_STATE');
}
