<ng-container
  *ngIf="(urlFriendlyProductLineEnum | json) !== '{}'"
  [ngSwitch]="productLineName"
>
  <img
    class="logo"
    ngSrc="assets/img/logo.svg"
    (click)="openAdditionalInfoDialog()"
    alt="PALFINGER logo"
    priority
    height="20"
    width="90"
    data-test="logo_btn"
    mat-button
  />
  <ds-equipment-icon
    *ngSwitchCase="urlFriendlyProductLineEnum.TimberCrane"
    type="010"
    [width]="50"
    class="product-line-icon"
  ></ds-equipment-icon>
  <ds-equipment-icon
    *ngSwitchCase="urlFriendlyProductLineEnum.ShortTimber"
    type="010"
    [width]="50"
    class="product-line-icon"
  ></ds-equipment-icon>
  <ds-equipment-icon
    *ngSwitchCase="urlFriendlyProductLineEnum.HookAndSkipLoaders"
    type="007"
    [maxHeight]="50"
    class="product-line-icon"
  ></ds-equipment-icon>
  <ds-equipment-icon
    *ngSwitchCase="urlFriendlyProductLineEnum.AccessPlatforms"
    type="003"
    [maxHeight]="35"
    class="product-line-icon"
  ></ds-equipment-icon>
  <ds-equipment-icon
    *ngSwitchCase="urlFriendlyProductLineEnum.TruckMountedForklifts"
    type="004"
    [maxHeight]="50"
    class="product-line-icon"
  ></ds-equipment-icon>
  <ds-equipment-icon
    *ngSwitchDefault
    type="006"
    [maxHeight]="50"
    class="product-line-icon"
  ></ds-equipment-icon>
</ng-container>
