import { ConfigurationOption } from '../generated/model/configurationOption';

export const testConfigurationOption: ConfigurationOption = {
  id: 1,
  name: 'Test ConfigurationOption Name',
  description: 'Test ConfigurationOption Description',
  technical_description: 'Test ConfigurationOption Technical Description',
  customer_value: 'Test ConfigurationOption Customer Value',
  picture_url: 'Test ConfigurationOption Picture Url',
  value: 'Test ConfigurationOption Value',
  value_description: 'Test ConfigurationOption Value Description',
  deleted: false,
  sales_price: 2,
  purchase_price: 3,
  sort_key: 4,
  state: 'SELECTED',
};
