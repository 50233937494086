/**
 * ProductConfigurator Backend
 * ProductConfigurator Backend APIs
 *
 * The version of the OpenAPI document: product-internal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PackageOptionCompare,
  PackageOptionCompareCompareDifferenceType,
} from '../generated';

export const testPackageOptionCompare: PackageOptionCompare = {
  package_id: 1,
  sales_option_id: 'test sales option id',
  compare_difference_type: PackageOptionCompareCompareDifferenceType.Added,
};
