import {
  ProjectInput,
  ProjectInputState,
} from '../generated/model/projectInput';

export const testProjectInput: ProjectInput = {
  name: 'Test Name',
  comment: 'Test Comment',
  chance: 1,
  customer_name: 'Test Customer Name',
  customer_number: 'Test Customer Number',
  state: ProjectInputState.InProgress,
  industry_id: 'Test Industry Id',
  industry_name: 'Test Industry Name',
  closed_date: new Date(),
  external_id: 'Test External Id',
  external_log_id: 'Test External Log Id',
  is_whole_sale_order: false,
};
