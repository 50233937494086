import {
  CrmAccountResponse,
  CrmSegmentItemResponse,
} from '@data-access/bpm-generated';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { CrmCustomerFilter } from '../models';
import { CrmCustomerActions } from './crm-customer.actions';

export interface CrmCustomerState extends EntityState<CrmAccountResponse> {
  filter: CrmCustomerFilter;
  isLoading: boolean;
  loadingLookup: { [accountId: string]: boolean };
  isUnused: boolean;
  upsertResult?: CrmAccountResponse;
  segments?: CrmSegmentItemResponse[];
}

export const adapter: EntityAdapter<CrmAccountResponse> =
  createEntityAdapter<CrmAccountResponse>({
    selectId: (entity) => entity.account_id,
  });

const initialState: CrmCustomerState = adapter.getInitialState({
  filter: {
    companyName: '',
  },
  isLoading: false,
  loadingLookup: {},
  isUnused: true,
});

const reducer = createReducer(
  initialState,
  on(CrmCustomerActions.LoadSegmentsSuccess, (state, { payload }) => ({
    ...state,
    segments: payload.segments,
  })),
  on(CrmCustomerActions.FilterChange, (state, { payload }) => ({
    ...state,
    filter: payload,
  })),
  on(CrmCustomerActions.LoadCustomer, (state, { payload }) => ({
    ...state,
    loadingLookup: { ...state.loadingLookup, [payload]: true },
  })),
  on(CrmCustomerActions.LoadCustomerSuccess, (state, { payload }) => ({
    ...adapter.upsertOne(payload, state),
    loadingLookup: { ...state.loadingLookup, [payload.account_id]: false },
  })),
  on(CrmCustomerActions.LoadCustomerError, (state, { payload }) => ({
    ...state,
    loadingLookup: { ...state.loadingLookup, [payload]: false },
  })),
  on(CrmCustomerActions.LoadCustomerList, (state) => ({
    ...state,
    isLoading: true,
    isUnused: false,
  })),
  on(CrmCustomerActions.LoadCustomerListError, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(CrmCustomerActions.LoadCustomerListSuccess, (state, { payload }) => ({
    ...adapter.setAll(payload.accounts, state),
    isLoading: false,
  })),
  on(CrmCustomerActions.UpsertCustomer, (state) => ({
    ...state,
    isLoading: true,
    upsertResult: undefined,
  })),
  on(CrmCustomerActions.UpsertCustomerError, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(CrmCustomerActions.UpsertCustomerSuccess, (state, { payload }) => ({
    ...adapter.upsertOne(payload, state),
    isLoading: false,
    upsertResult: payload,
  })),
);

export function customerReducer(state, action: Action): CrmCustomerState {
  return reducer(state, action);
}
