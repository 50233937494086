import { MccItem } from '../generated';

export const testMccItem: MccItem = {
  original_sales_price: 299.99,
  quantity: 3,
  is_discountable: true,
  is_edited: false,
  company_bpm_id: 1234,
  cart_item_price_comparer_id: null,
  sort_key: 1,
  id: 'testItemId',
  cart_id: 5678,
  name: 'testMccItemName',
  sales_price: 199.99,
  purchase_price: 149.99,
  is_optional: false,
  cart_item_group_id: null,
  cit_material: 'testCitMaterial',
  truck_material: 'testTruckMaterial',
  sort_key_optional: 0,
};
