import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { dsConfig } from '@design-system/cdk/config';
import {
  SalesOption,
  SalesOptionState,
} from '@sales-libs/sc-configuration/data-access';

export function showSalesOptionStateReason(
  dialog: MatDialog,
  dialogComponent,
  salesOptions: SalesOption[],
  salesOption: SalesOption,
): SalesOption[] {
  const clickedSalesOptionDebug = salesOptions.filter(
    (singleSalesOption) => singleSalesOption.id === salesOption.id,
  );

  dialog.open(dialogComponent, {
    width: String(dsConfig.spacing * 25) + 'px',
    data: clickedSalesOptionDebug,
  });

  return clickedSalesOptionDebug;
}

export function openInfoDialog(
  dialog: MatDialog,
  dialogComponent,
  salesOption: SalesOption,
  sectionName: string,
  selectSalesOption: (result: SalesOption) => void,
) {
  const dialogRef: MatDialogRef<any> = dialog.open(dialogComponent, {
    width: '850px',
    data: { salesOption, sectionName },
    panelClass: 'so-details-dialog',
    autoFocus: false,
  });

  const instance = dialogRef.componentInstance;

  instance.salesOptionSelected.subscribe((result) => {
    selectSalesOption(result);
    instance.data.salesOption = { ...result, state: SalesOptionState.Selected };
  });
}
