/**
 * SalesSolutions Backend
 * Collection of APIs for SalesSolutions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ConfigurationAttribute } from './configurationAttribute';
import { ConfigurationPackage } from './configurationPackage';
import { ConfigurationOption } from './configurationOption';


/**
 * The class for a cart item dto creation based on a Palfinger.Configurator.Data.Entities.ProductItem.
 */
export interface ProductItemInput { 
    /**
     * The model id used by SAP of the Palfinger.Configurator.Project.Services.Models.CartItem.ProductItemDto.
     */
    
    model_id: string;
    /**
     * The sales object type of the Palfinger.Configurator.Project.Services.Models.CartItem.ProductItemDto.
     */
    
    object_type: string;
    /**
     * The product type of the Palfinger.Configurator.Project.Services.Models.CartItem.ProductItemDto.  <example>Product</example><example>Accessory</example>
     */
    
    product_type: ProductItemInputProductType;
    /**
     * The title of the corresponding image for this Palfinger.Configurator.Project.Services.Models.CartItem.ProductItemDto.
     */
    
    picture_name?: string | null;
    /**
     * The pim core product id to the Palfinger.Configurator.Project.Services.Models.CartItem.ProductItemDto.
     */
    
    pim_core_id?: string | null;
    /**
     * Marks, if the Palfinger.Configurator.Project.Services.Models.CartItem.ProductItemDto is configurable or not.
     */
    
    is_configurable: boolean;
    /**
     * Id of the item.  If null, the item is a new Palfinger.Configurator.Project.Services.Models.CartItem.ProductItemDto.
     */
    
    id?: string | null;
    /**
     * Cart id of the parent cart for this item.
     */
    
    cart_id: number;
    /**
     * The name of the Palfinger.Configurator.Project.Services.Models.CartItem.ProductItemDto.
     */
    
    name: string;
    /**
     * The original name of the Palfinger.Configurator.Project.Services.Models.CartItem.ProductItemDto.
     */
    
    original_name?: string | null;
    /**
     * The material id of the Palfinger.Configurator.Project.Services.Models.CartItem.ProductItemDto.
     */
    
    material_id?: string | null;
    /**
     * Provides a short description of the Palfinger.Configurator.Project.Services.Models.CartItem.ProductItemDto.
     */
    
    short_description?: string | null;
    /**
     * Provides a short description of the Palfinger.Configurator.Project.Services.Models.CartItem.ProductItemDto.
     */
    
    original_short_description?: string | null;
    /**
     * Provides a long description of the Palfinger.Configurator.Project.Services.Models.CartItem.ProductItemDto.
     */
    
    long_description?: string | null;
    /**
     * Provides a long description of the Palfinger.Configurator.Project.Services.Models.CartItem.ProductItemDto.
     */
    
    original_long_description?: string | null;
    /**
     * Possible options of the Palfinger.Configurator.Project.Services.Models.CartItem.ProductItemDto.  If null, the item is not configurable, e.g. pumps.
     */
    
    options?: Array<ConfigurationOption> | null;
    /**
     * Possible attributes of the Palfinger.Configurator.Project.Services.Models.CartItem.ProductItemUpdateDto.  If null, the item is not configurable, e.g. pumps.
     */
    
    attributes?: Array<ConfigurationAttribute> | null;
    /**
     * Customizable sales price of the item.
     */
    
    sales_price: number;
    /**
     * The original sales price of the Palfinger.Configurator.Project.Services.Models.CartItem.ProductItemDto.
     */
    
    original_sales_price?: number | null;
    /**
     * Quantity of the item.
     */
    
    quantity: number;
    /**
     * Quantity of the item.
     */
    
    original_quantity?: number | null;
    /**
     * Purchase price of the item.
     */
    
    purchase_price: number;
    /**
     * The original purchase price of the Palfinger.Configurator.Project.Services.Models.CartItem.ProductItemDto.
     */
    
    original_purchase_price?: number | null;
    /**
     * Flags, if the Palfinger.Configurator.Project.Services.Models.CartItem.ProductItemDto has a valid configuration or not.
     */
    
    is_valid: boolean;
    /**
     * Flags, if  the Palfinger.Configurator.Project.Services.Models.CartItem.ProductItemDto is edited
     */
    
    is_edited: boolean;
    /**
     * Flags, if the Palfinger.Configurator.Project.Services.Models.CartItem.ProductItemDto is set to optional.
     */
    
    is_optional: boolean;
    /**
     * Flags, if the Palfinger.Configurator.Project.Services.Models.CartItem.ProductItemDto is discountable.
     */
    
    is_discountable: boolean;
    /**
     * Marks, if the Palfinger.Configurator.Project.Services.Models.CartItem.ProductItemDto was originally discountable or not.
     */
    
    original_is_discountable?: boolean | null;
    /**
     * Flags, if the Palfinger.Configurator.Project.Services.Models.CartItem.ProductItemDto is marked to be considered for dynamic pricing based on ppi.
     */
    
    is_ppi_relevant: boolean;
    /**
     * Flags, if the Palfinger.Configurator.Project.Services.Models.CartItem.ProductItemDto was originally marked to be considered for dynamic pricing based on ppi.
     */
    
    original_is_ppi_relevant?: boolean | null;
    /**
     * Cart id group in case of the item is in a created group.
     */
    
    cart_item_group_id?: number | null;
    /**
     * Possible packages of the Palfinger.Configurator.Project.Services.Models.CartItem.ProductItemDto.
     */
    
    packages?: Array<ConfigurationPackage> | null;
    /**
     * Sorting by FE.
     */
    
    sort_key: number;
    /**
     * Sorting by FE for optional items.
     */
    
    sort_key_optional: number;
    /**
     * Cart item of the target cart item which should be compared to the actual one in regards to the price.
     */
    
    cart_item_price_comparer_id?: string | null;
    /**
     * Internal cpq value
     */
    
    epp?: string | null;
    /**
     * Original Internal cpq value
     */
    
    eopp?: string | null;
}
export enum ProductItemInputProductType {
    Product = 'Product',
    Accessory = 'Accessory'
};



