import {
  CalculationItem,
  CalculationItemItemType,
} from '../generated/model/calculationItem';

import { testCalculationItemPerspective } from './calculationItemPerspective';

export const testCalculationItemOptions: CalculationItem = {
  id: '4',
  cart_item_id: 42,
  cart_item_group_id: 43,
  name: 'Test Calculation Item 4',
  description: 'Test CalculationItem Description 4',
  quantity: 4,
  item_type: CalculationItemItemType.Option,
  is_optional: false,
  is_read_only: true,
  is_ppi_relevant: true,
  general_agent: testCalculationItemPerspective,
  partner: testCalculationItemPerspective,
  customer: testCalculationItemPerspective,
};

export const testCalculationItem: CalculationItem = {
  id: '1',
  cart_item_id: 2,
  cart_item_group_id: 3,
  name: 'Test Calculation Item',
  description: 'Test CalculationItem Description',
  quantity: 1,
  item_type: CalculationItemItemType.Item,
  is_optional: false,
  is_read_only: false,
  is_ppi_relevant: true,
  general_agent: testCalculationItemPerspective,
  partner: testCalculationItemPerspective,
  customer: testCalculationItemPerspective,
  items: [testCalculationItemOptions],
};

export const testCalculationGroupItem: CalculationItem = {
  id: '2',
  cart_item_id: 22,
  cart_item_group_id: 23,
  name: 'Test Calculation Item 2',
  description: 'Test Calculation Item Description 2',
  quantity: 2,
  item_type: CalculationItemItemType.GroupItem,
  is_optional: true,
  is_read_only: false,
  is_ppi_relevant: true,
  general_agent: testCalculationItemPerspective,
  partner: testCalculationItemPerspective,
  customer: testCalculationItemPerspective,
};

export const testCalculationGroup: CalculationItem = {
  id: '3',
  cart_item_id: 32,
  cart_item_group_id: 33,
  name: 'Test Calculation Item 3',
  description: 'Test Calculation Item Description 3',
  quantity: 3,
  item_type: CalculationItemItemType.Group,
  is_optional: true,
  is_read_only: false,
  is_ppi_relevant: true,
  general_agent: testCalculationItemPerspective,
  partner: testCalculationItemPerspective,
  customer: testCalculationItemPerspective,
  items: [testCalculationGroupItem],
};
