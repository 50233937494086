/**
 * SalesSolutions Backend
 * Collection of APIs for SalesSolutions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ConfigurationMedia } from './configurationMedia';
import { SalesPriceListOptionMappingValue } from './salesPriceListOptionMappingValue';


/**
 * Holds the sales price list option data.
 */
export interface SalesPriceListOption { 
    /**
     * Gets or sets the sales price list option name.
     */
    
    name: string;
    /**
     * Gets or sets the sales price list option price.
     */
    
    price?: number | null;
    /**
     * Gets or sets the sales price list option purchase price.
     */
    
    purchase_price?: number | null;
    /**
     * Wildcard values
     */
    
    available_values?: Array<SalesPriceListOptionMappingValue> | null;
    /**
     * Wildcard mapping value
     */
    
    value?: string | null;
    
    image?: ConfigurationMedia;
    /**
     * Gets or sets the gallery of the sales price list option.
     */
    
    gallery?: Array<ConfigurationMedia> | null;
    /**
     * Gets or sets the technical description of the sales price list option.
     */
    
    technical_description?: string | null;
    /**
     * Gets or sets the customer value of the sales price list option.
     */
    
    customer_value?: string | null;
    /**
     * Gets or sets the description of the sales price list option.
     */
    
    description?: string | null;
    /**
     * Gets or sets the comment of the sales price list option.
     */
    
    comment?: string | null;
    /**
     * Gets or sets the preconditions of the sales price list option.
     */
    
    precondition?: Array<string> | null;
    /**
     * Gets or sets an array of strings representing the entities that disable the sales price list option.
     */
    
    disabled_by?: Array<string> | null;
    /**
     * Gets or sets the error messages related to the sales price list option.
     */
    
    error_messages?: Array<string> | null;
    /**
     * Gets or sets the state of the sales price list option.
     */
    
    state: SalesPriceListOptionState;
    
    is_upselling_option: boolean;
    
    sort_key_sap: number;
    /**
     * Used for non-configurable price lists where a sales option is a sap material
     */
    
    material_id?: string | null;
    /**
     * Gets or set whether the sales option item is inherited.
     */
    
    is_inherited: boolean;
    /**
     * CPQ Internal
     */
    
    epp?: string | null;
}
export enum SalesPriceListOptionState {
    SELECTED = 'SELECTED',
    DESELECTED = 'DESELECTED',
    SELECTABLE = 'SELECTABLE',
    INVALID = 'INVALID',
    REQUIRED = 'REQUIRED'
};



