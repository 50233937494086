<h3 matDialogTitle>
  <ng-container *ngIf="customer; else newCustomer">
    {{ customer.company_name }}
    <small *ngIf="customer.company_name2">{{ customer.company_name2 }}</small>
  </ng-container>
  <ng-template #newCustomer>
    {{ 'customer.btn_new_customer' | translate }}
  </ng-template>
</h3>
<mat-dialog-content>
  <form [formGroup]="form">
    <div
      fxLayout="row"
      fxLayoutAlign="space-between"
      [fxLayoutGap]="1 | dsSpacing"
      fxLayout.xs="column"
      fxLayoutGap.xs="0"
    >
      <div fxFlex>
        <mat-form-field class="full-width">
          <mat-label>{{ 'customer.company_name' | translate }}</mat-label>
          <input
            matInput
            maxlength="35"
            [formControl]="form.controls.name"
            required
          />
          <mat-error>
            {{ 'customer.not_empty_hint' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex>
        <mat-form-field class="full-width">
          <mat-label>{{ 'customer.company_name' | translate }} 2</mat-label>
          <input matInput maxlength="35" [formControl]="form.controls.name2" />
        </mat-form-field>
      </div>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="space-between"
      [fxLayoutGap]="1 | dsSpacing"
      fxLayout.xs="column"
      fxLayoutGap.xs="0"
    >
      <div fxFlex>
        <mat-form-field class="full-width">
          <mat-label>{{ 'customer.street' | translate }}</mat-label>
          <input
            matInput
            maxlength="35"
            [formControl]="form.controls.street_name"
            required
          />
          <mat-error>
            {{ 'customer.not_empty_hint' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex>
        <mat-form-field class="full-width">
          <mat-label>{{ 'customer.house_number' | translate }}</mat-label>
          <input
            matInput
            maxlength="10"
            [formControl]="form.controls.house_number"
            required
          />
          <mat-error>
            {{ 'customer.not_empty_hint' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div
      fxLayout="row"
      [fxLayoutGap]="(1 | dsSpacing) + ' grid'"
      fxLayout.xs="column"
      fxLayoutGap.xs="0"
    >
      <div fxFlex="50%" fxFlex.xs="auto">
        <div
          fxLayout="row"
          [fxLayoutGap]="1 | dsSpacing"
          fxLayout.xs="column"
          fxLayoutGap.xs="0"
        >
          <div fxFlex="70px" fxFlex.xs="auto">
            <mat-form-field class="full-width">
              <mat-label>{{ 'customer.zip' | translate }}</mat-label>
              <input
                matInput
                maxlength="10"
                [formControl]="form.controls.postal_code"
                required
              />
              <mat-error>
                {{ 'customer.not_empty_hint' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div [fxFlex]="'0 0 calc(100% - 70px - ' + (1 | dsSpacing) + ')'">
            <mat-form-field class="full-width">
              <mat-label>{{ 'customer.city' | translate }}</mat-label>
              <input
                matInput
                maxlength="35"
                [formControl]="form.controls.city"
                required
              />
              <mat-error>
                {{ 'customer.not_empty_hint' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div fxFlex>
        <ng-container *ngIf="countries$ | async as countries; selectLoading">
          <ds-filter-input
            [data]="countries$ | async"
            [control]="form.controls.country_code"
            [label]="'customer.country' | translate"
            [isMultiChoiceDisabled]="true"
          >
            <mat-error>
              {{ 'customer.not_empty_hint' | translate }}
            </mat-error>
          </ds-filter-input>
        </ng-container>
        <ng-template #selectLoading>
          <ds-loading-placeholder
            [height]="2.5 | dsSpacing"
            [width]="11.75 | dsSpacing"
          >
          </ds-loading-placeholder>
        </ng-template>
      </div>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="space-between"
      [fxLayoutGap]="1 | dsSpacing"
      fxLayout.xs="column"
      fxLayoutGap.xs="0"
    >
      <div fxFlex>
        <mat-form-field class="full-width">
          <mat-label>{{ 'customer.email' | translate }}</mat-label>
          <input matInput maxlength="50" [formControl]="form.controls.email" />
          <mat-error>
            {{ 'general.invalid_email' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex>
        <mat-form-field class="full-width">
          <mat-label>{{ 'customer.website' | translate }}</mat-label>
          <input matInput maxlength="30" [formControl]="form.controls.url" />
        </mat-form-field>
      </div>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="space-between"
      [fxLayoutGap]="1 | dsSpacing"
      fxLayout.xs="column"
      fxLayoutGap.xs="0"
    >
      <div fxFlex>
        <mat-form-field class="full-width">
          <mat-label>{{ 'customer.phone' | translate }}</mat-label>
          <input
            matInput
            maxlength="16"
            [formControl]="form.controls.phone_number"
          />
        </mat-form-field>
      </div>
      <div fxFlex>
        <mat-form-field class="full-width">
          <mat-label>{{ 'customer.fax' | translate }}</mat-label>
          <input matInput maxlength="31" [formControl]="form.controls.fax" />
        </mat-form-field>
      </div>
    </div>
    <div>
      <mat-form-field
        class="full-width"
        data-test="segments-form"
        [spotlight]="selectCustomerSegmentation"
      >
        <mat-label>
          {{ 'customer.segments_and_markets' | translate }}
        </mat-label>
        <mat-select
          data-test="segment-select"
          [formControl]="form.controls.market_ids"
          required
          multiple
        >
          <ng-container *ngIf="segments$ | async as segments; selectLoading">
            <mat-optgroup
              *ngFor="let segment of segments"
              [label]="segment | cpqSegmentsTranslation"
            >
              <mat-option
                *ngFor="let market of segment.markets"
                [value]="market.id"
                >{{ market | cpqMarketsTranslation }}</mat-option
              >
            </mat-optgroup>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button matDialogClose>
    {{ 'customer.close' | translate }}
  </button>
  <button
    mat-raised-button
    [disabled]="form.invalid"
    color="primary"
    (click)="upsertCustomer()"
    [dsButtonPending]="isLoading$ | async"
    data-test="confirm-button"
  >
    {{ 'customer.confirm' | translate }}
  </button>
</mat-dialog-actions>

<ds-spotlight
  id="cpq-spotlight-project-overview-selectCustomerSegmentation"
  #selectCustomerSegmentation
  position="right"
>
  {{ 'customer.spotlight.selectCustomerSegmentation' | translate }}
</ds-spotlight>
