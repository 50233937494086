import {
  ProjectUpdate,
  ProjectUpdateState,
} from '../generated/model/projectUpdate';

export const testProjectUpdate: ProjectUpdate = {
  name: 'Test Name',
  comment: 'Test Comment',
  chance: 90,
  customer_name: 'Test Customer Name',
  customer_number: 'Test Customer Number',
  state: ProjectUpdateState.InProgress,
  industry_id: 'Test Industry Id',
  industry_name: 'Test Industry Name',
  closed_date: new Date(),
  is_whole_sale_order: false,
};
