/**
 * SalesSolutions Backend
 * Collection of APIs for SalesSolutions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompareDifference } from './compareDifference';
import { ConfigurationOptionCompare } from './configurationOptionCompare';
import { PackageCompare } from './packageCompare';


/**
 * Represents a Data Transfer Object (DTO) for comparing cart items.
 */
export interface CartItemCompare { 
    /**
     * The name of the cart item.
     */
    
    name?: string | null;
    /**
     * The original name of the cart item.
     */
    
    original_name?: string | null;
    /**
     * The URL of the cart item\'s image.
     */
    
    image_url?: string | null;
    /**
     * The type of the cart item.
     */
    
    cart_item_type: CartItemCompareCartItemType;
    /**
     * The type of differences to compare.
     */
    
    compare_difference_type: CartItemCompareCompareDifferenceType;
    /**
     * A short description of the cart item.
     */
    
    short_description?: string | null;
    /**
     * The original short description of the cart item.
     */
    
    original_short_description?: string | null;
    /**
     * A long description of the cart item.
     */
    
    long_description?: string | null;
    /**
     * The original long description of the cart item.
     */
    
    original_long_description?: string | null;
    /**
     * The sales price of the cart item.
     */
    
    sales_price: number;
    /**
     * The original sales price of the cart item, if available.
     */
    
    original_sales_price?: number | null;
    /**
     * The name of the comparer for the cart item.
     */
    
    comparer_name?: string | null;
    /**
     * Indicates whether the cart item is optional.
     */
    
    is_optional: boolean;
    /**
     * Indicates whether the cart item has been edited.
     */
    
    is_edited: boolean;
    /**
     * A list of configuration options to compare, if available.
     */
    
    options?: Array<ConfigurationOptionCompare> | null;
    /**
     * A list of packages to compare, if available.
     */
    
    packages?: Array<PackageCompare> | null;
    
    changes?: CompareDifference;
    /**
     * The original purchase price of the cart item.
     */
    
    original_purchase_price?: number | null;
    /**
     * The original quantity of the cart item.
     */
    
    original_quantity?: number | null;
    /**
     * The original discountability of the cart item.
     */
    
    original_is_discountable?: boolean | null;
    /**
     * The original PPI relevance of the cart item.
     */
    
    original_is_ppi_relevant?: boolean | null;
}
export enum CartItemCompareCartItemType {
    Crane = 'Crane',
    Accessory = 'Accessory',
    Custom = 'Custom',
    TuningCenter = 'TuningCenter',
    Option = 'Option',
    WarrantyExtension = 'WarrantyExtension',
    Contract = 'Contract',
    MCC = 'MCC',
    Package = 'Package',
    ConfigurationOption = 'ConfigurationOption'
};
export enum CartItemCompareCompareDifferenceType {
    Added = 'Added',
    Changed = 'Changed',
    Removed = 'Removed',
    Unchanged = 'Unchanged'
};



