import { InjectionToken } from '@angular/core';

export const FEATURE_FLAG_3D_VIEW = new InjectionToken<string>(
  'FEATURE_FLAG_3D_VIEW',
);

export const FEATURE_FLAG_PPI = new InjectionToken<string>('FEATURE_FLAG_PPI');

export const FEATURE_FLAG_MCC = new InjectionToken<string>('FEATURE_FLAG_MCC');

export const FEATURE_FLAG_EARLY_ACCESS = new InjectionToken<string>(
  'FEATURE_FLAG_EARLY_ACCESS',
);

export const FEATURE_FLAG_SERVICE_CONTRACTS = new InjectionToken<string>(
  'FEATURE_FLAG_SERVICE_CONTRACTS',
);

export const FEATURE_FLAG_BENEFITS = new InjectionToken<string>(
  'FEATURE_FLAG_BENEFITS',
);
export const FEATURE_FLAG_BULK_ORDERS = new InjectionToken<string>(
  'FEATURE_FLAG_BULK_ORDERS',
);
export const FEATURE_FLAG_WHOLESALE_CHECKBOX = new InjectionToken<string>(
  'FEATURE_FLAG_WHOLESALE_CHECKBOX',
);

export const FEATURE_FLAG_PALSHOW = new InjectionToken<string>(
  'FEATURE_FLAG_PALSHOW',
);

export const FEATURE_FLAG_PROJECT_SETTINGS = new InjectionToken<string>(
  'FEATURE_FLAG_PROJECT_SETTINGS',
);

export const FEATURE_FLAG_REPORTING = new InjectionToken<string>(
  'FEATURE_FLAG_REPORTING',
);

export const FEATURE_FLAG_SOLUTIONS_INTEGRATION = new InjectionToken<string>(
  'FEATURE_FLAG_SOLUTIONS_INTEGRATION',
);

export const FEATURE_FLAG_BEST_INVEST = new InjectionToken<string>(
  'FEATURE_FLAG_BEST_INVEST',
);

export const FEATURE_FLAG_DIGITAL_OFFER = new InjectionToken<string>(
  'FEATURE_FLAG_DIGITAL_OFFER',
);

export const CART_MAX_VALID_UNTIL_DAYS = new InjectionToken<number>(
  'CART_MAX_VALID_UNTIL_DAYS',
);

export const PALDESK_BASE_BATH_TOKEN = new InjectionToken<string>(
  'PALDESK_BASE_BATH_TOKEN',
);

export const SCC_DOMAIN = new InjectionToken<string>('SCC_DOMAIN');

export const PALSHOW_DOMAIN = new InjectionToken<string>('PALSHOW_DOMAIN');

export const PALFINGER_API_MANAGEMENT_SUBS_KEY = new InjectionToken<string>(
  'PALFINGER_API_MANAGEMENT_SUBS_KEY',
);
