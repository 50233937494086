import { SalesPriceListPackage } from '../generated/model/salesPriceListPackage';
import { testSalesPricelistPackageOption as testSalesPriceListPackageOption } from './salesPricelistPackageOption';

export const testSalesPriceListPackage: SalesPriceListPackage = {
  name: 'Test SalesPriceListPackage Name',
  model_id: 'Test SalesPriceListPackage Model Id',
  sales_price: 1,
  purchase_price: 2,
  description: 'Test SalesPriceListPackage Description',
  icon_name: 'Test SalesPriceListPackage Icon',
  sort_key: 1,
  options: [testSalesPriceListPackageOption],
  epp: 'Test epp',
};
