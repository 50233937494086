import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import {
  ComponentPortal,
  ComponentType,
  PortalInjector,
} from '@angular/cdk/portal';
import { Injectable, InjectionToken, Injector } from '@angular/core';
import { PrintComponent, PrintServiceConfig } from './print.component';

export const PRINT_DATA = new InjectionToken<any>('PrintData');
export const PRINT_SERVICE = new InjectionToken<PrintService>('PrintService');

const defaultConfig: PrintServiceConfig = {
  headerHeight: '50px',
  footerHeight: '50px',
};

/**
 * print service that uses a overlay to print only selected component
 * (no need to hide everything else)
 */
@Injectable()
// https://medium.com/@Idan_Co/angular-print-service-290651c721f9
// https://github.com/IdanCo/angular-print-service
export class PrintService {
  private overlayRef: OverlayRef;

  constructor(
    private _overlay: Overlay,
    private _injector: Injector,
  ) {}

  loadComponent<T>(
    componentRef: ComponentType<T>,
    // eslint-disable-next-line @typescript-eslint/ban-types
    data?: Object,
    config = defaultConfig,
  ) {
    this.overlayRef = this._overlay.create({
      width: '100vw',
      height: 'auto',
      maxHeight: 'auto',
    });
    const printPortal = new ComponentPortal(PrintComponent);
    const printRef = this.overlayRef.attach<PrintComponent>(printPortal);
    printRef.instance.config = config;
    const injector = data
      ? new PortalInjector(
          this._injector,
          new WeakMap([
            [PRINT_DATA, data],
            [PRINT_SERVICE, this],
          ]),
        )
      : this._injector;
    const componentPortal = new ComponentPortal(
      componentRef,
      undefined,
      injector,
    );
    printRef.instance.attachComponentPortal(componentPortal);
  }

  onDataReady() {
    if (!this.overlayRef) return;
    setTimeout(() => {
      window.print();
      this.overlayRef.dispose();
    });
  }
}
