import { Cart, CartState } from '../generated/model/cart';
import { testCartItemGroup } from './cartItemGroup';
import { testContractItem } from './contractItem';
import { testCurrency } from './currency';
import { testCustomArticleItem } from './customArticleItem';
import { testMccItem } from './mccItem';
import { testProductItem } from './productItem';
import { testSalesOptionCartItem } from './salesOptionCartItem';
import { testTuningCenterItem } from './tuningCenterItem';
import { testWarrantyExtensionItem } from './warrantyExtensionItem';

export const testCart: Cart = {
  optional_cart_item_groups: [testCartItemGroup],
  original_cart_id: 0,
  id: 1,
  model: 'Test Cart',
  locked: false,
  created_on: new Date(),
  version: 1,
  active: false,
  valid: false,
  state: CartState.Created,
  final_price: 1,
  sales_price: 1,
  project_id: 1,
  comment: 'Test Comment',
  price_list_date: new Date(),
  product_line: 'Test Product Line',
  language: 'en',
  product_items: [testProductItem],
  contract_items: [testContractItem],
  custom_article_items: [testCustomArticleItem],
  mcc_items: [testMccItem],
  sales_option_items: [testSalesOptionCartItem],
  tuning_center_items: [testTuningCenterItem],
  warranty_extension_items: [testWarrantyExtensionItem],
  currency_settings: testCurrency,
  offer_created_on: new Date(),
  valid_until: new Date(),
  modified_on: new Date(),
};
