/**
 * SalesSolutions Backend
 * Collection of APIs for SalesSolutions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The class represents the data for the project.
 */
export interface Project { 
    /**
     * Id of the given project.
     */
    
    id: number;
    /**
     * Code of the given project.
     */
    
    code: string;
    /**
     * Username, who created the given project.
     */
    
    created_by: string;
    /**
     * Cart model from active solution.
     */
    
    model?: string | null;
    /**
     * Final price of active cart.
     */
    
    project_volume: number;
    /**
     * The creation date of the project.
     */
    
    created_on: Date;
    /**
     * Indicates if the project has an offer.
     */
    
    has_offer: boolean;
    /**
     * Frontend relevant information for possible actions.
     */
    
    readonly actions: Array<ProjectActions>;
    /**
     * Id of thr dealers company, who created the given project.
     */
    
    company_bpm_id: number;
    /**
     * In case of creation from external system, the external id is set.
     */
    
    external_id?: string | null;
    /**
     * In case of creation from external system, the external log id is set.
     */
    
    external_log_id?: string | null;
    /**
     * The name of the project.
     */
    
    name: string;
    /**
     * The comment of the project.
     */
    
    comment?: string | null;
    /**
     * The chance of the project.
     */
    
    chance: number;
    /**
     * The customer name of the project.
     */
    
    customer_name?: string | null;
    /**
     * The customer number of the project.
     */
    
    customer_number?: string | null;
    /**
     * The external customer number of the project.
     */
    
    external_customer_number?: string | null;
    /**
     * The state of the project.
     */
    
    state?: ProjectState | null;
    /**
     * The industry id to the project.
     */
    
    industry_id?: string | null;
    /**
     * The industry name to the project.
     */
    
    industry_name?: string | null;
    /**
     * The end date of the project.
     */
    
    closed_date?: Date | null;
    /**
     * Identifies, if the project is a whole sale order.
     */
    
    is_whole_sale_order: boolean;
}
export enum ProjectActions {
    Edit = 'Edit',
    Delete = 'Delete',
    CreateLostOrder = 'CreateLostOrder',
    EditLostOrder = 'EditLostOrder'
};
export enum ProjectState {
    InProgress = 'InProgress',
    Offer = 'Offer',
    Won = 'Won',
    Lost = 'Lost',
    Closed = 'Closed'
};



